import { Form, Select, Tooltip } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTipoOp, setTipoOpParada } from "../../store/tipoOpActions";
import { setFocus } from "../../store/actions";
import { setSafra } from "../../store/safraActions";
import { setCultura } from "../../store/culturaActions";
import { ativarProcess } from "../../store/actions";
import { IFazenda } from "../../reducers/FazendaReducer";
import {
    ITipoOp,
    ITipoOpClienteList,
    ITipoOpList,
} from "../../reducers/TipoOpReducer";
import { IMenu } from "../../reducers/MenuReducer";
import { v4 as uuidv4 } from "uuid";
import { IStore } from "../../store";
import { IProcessList } from "../../reducers/ProcessReducer";
import { IPreferencia } from "../../reducers/PreferenciaReducer";
import { IStatusList } from "../../store/types";
import { getEquipamentoListFiltro } from "../../store/polylineAction";
import { ITime } from "../../reducers/TimeReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import { IEquipamento } from "../../reducers/EquipamentoReducer";
import { useEffect, useState } from "react";

const { Option, OptGroup } = Select;

interface ITipoOpController {
    fazenda: IFazenda;
    tipoOps: ITipoOpList;
    menuState?: IMenu;
    processListState?: IProcessList;
    setTipoOp: Function;
    ativarProcess: Function;
    setCultura: Function;
    setFocus: Function;
    setSafra: Function;
    prefs?: IPreferencia;
    loader?: IStatusList;
    getEquipamentoListFiltro: Function;
    time?: ITime;
    tema?: ITheme;

    equipamento?: IEquipamento;

    tipoOpList: ITipoOpClienteList;

    setTipoOpParada: Function;
}
export function TipoOpController(props: ITipoOpController) {
    const {
        fazenda,
        setFocus,
        tipoOps,
        setTipoOp,
        tema,
        equipamento,
        tipoOpList,

        setTipoOpParada,
    } = props;

    const [haParad, setHaParad] = useState(false);
    const [haDesloc, setHaDesloc] = useState(false);
    const [haOpe, setHaOpe] = useState(false);

    function handleChangeTipoOp(value: any) {
        switch (value) {
            case "all":
                setFocus({ view: 7, subview: null });
                setTipoOp({});
                setTipoOpParada({});
                break;
            case "naoIdentificadoOpe":
                setFocus({ view: 7, subview: null });
                setTipoOp({ id_tipo_operacao: 0 });
                setTipoOpParada({});
                break;
            case "naoIdentificado":
                setFocus({ view: 7, subview: null });
                setTipoOpParada({ id_tipo_operacao: 0 });
                setTipoOp({});
                break;
            // case value >= 800 && value < 900:
            //     console.log("aqui");
            //     setFocus({ view: 7, subview: null });
            //     setTipoOpParada(
            //         tipoOps[
            //             tipoOps.findIndex(
            //                 (t: ITipoOp) => t.id_tipo_operacao === value
            //             )
            //         ]
            //     );
            //     setTipoOp({});
            //     break;
            default:
                setFocus({ view: 7, subview: null });
                if (value >= 800 && value < 900) {
                    setTipoOpParada(
                        tipoOps[
                            tipoOps.findIndex(
                                (t: ITipoOp) => t.id_tipo_operacao === value
                            )
                        ]
                    );
                    setTipoOp({});
                } else {
                    setTipoOp(
                        tipoOps[
                            tipoOps.findIndex(
                                (t: ITipoOp) => t.id_tipo_operacao === value
                            )
                        ]
                    );
                    setTipoOpParada({});
                }
        }

        // if (value === "all") {
        //     setFocus({ view: 7, subview: null });
        //     setTipoOp({});
        // } else if (value === "naoIdentificado") {
        //     setFocus({ view: 7, subview: null });
        //     setTipoOp({ id_tipo_operacao: 0 });
        // } else {
        //     setFocus({ view: 7, subview: null });
        //     setTipoOp(
        //         tipoOps[
        //             tipoOps.findIndex(
        //                 (t: ITipoOp) => t.id_tipo_operacao === value
        //             )
        //         ]
        //     );
        // }

        // ativarProcess({
        //     menuItem: menuState[menuState.findIndex(m => m.id === 6)],
        //     processList: processListState,
        // });
        // setFocus(3);
    }

    const filteredOpeOptions = tipoOps.filter((o: any) =>
        tipoOpList.some((s: any) => s.tipo_operacao_temp === o.id_tipo_operacao)
    );

    useEffect(() => {
        if (filteredOpeOptions.length > 0) {
            filteredOpeOptions.forEach((o: any) => {
                if (o.id_tipo_operacao > 0 && o.id_tipo_operacao < 800) {
                    setHaOpe(true);
                } else if (
                    o.id_tipo_operacao >= 900 &&
                    o.id_tipo_operacao <= 999
                ) {
                    setHaDesloc(true);
                } else if (
                    o.id_tipo_operacao >= 800 &&
                    o.id_tipo_operacao < 900
                ) {
                    setHaParad(true);
                }
            });
        }
    }, [filteredOpeOptions, tipoOpList]);

    return (
        <>
            <Form>
                <Form.Item
                    name="tipoOp"
                    label={
                        <span
                            style={{
                                color: tema ? "#fff" : "#606060",
                                paddingLeft: "2px",
                                fontSize: "18px",
                            }}
                        >
                            {" "}
                            <Tooltip title="Tipo de Operação">
                                <i className="fas fa-hard-hat"></i>
                            </Tooltip>
                        </span>
                    }
                    colon={false}
                >
                    <Select
                        style={{
                            width: 200,
                            color: tema ? "#fff" : "#606060",
                            border: "1px solid #C1C1C1",
                            backgroundColor: tema ? "#757575" : "",
                        }}
                        size="small"
                        disabled={fazenda.id_fazenda === ""}
                        bordered={false}
                        placeholder="Escolha"
                        // optionLabelProp="label"
                        suffixIcon={<i className="fas fa-chevron-right"></i>}
                        // notFoundContent={
                        //     <Empty
                        //         description="Nenhuma operação foi carregada."
                        //         image={Empty.PRESENTED_IMAGE_SIMPLE}
                        //     />
                        // }
                        // dropdownRender={menu => <div>{menu}</div>}
                        onChange={handleChangeTipoOp}
                        onSelect={handleChangeTipoOp}
                        dropdownClassName="agrochip-dropdown"
                    >
                        {filteredOpeOptions.length > 0 ? (
                            <>
                                <Option
                                    value="all"
                                    label="Todos"
                                    key={uuidv4()}
                                >
                                    <div
                                        style={{
                                            display: "flex",

                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>Todos tipos de operação</div>
                                    </div>
                                </Option>
                                <OptGroup label="Tipo Operação">
                                    {haOpe ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao > 0 &&
                                                        e.id_tipo_operacao <
                                                            800 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhuma op.
                                                    cadastrada.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                    <Option
                                        value="naoIdentificadoOpe"
                                        label="Não Identificado"
                                        key={uuidv4()}
                                    >
                                        <div
                                            style={{
                                                display: "flex",

                                                flexDirection: "column",
                                            }}
                                        >
                                            <div>Não Identificado</div>
                                        </div>
                                    </Option>
                                </OptGroup>
                                <OptGroup label="Tipo Deslocamento">
                                    {haDesloc ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao >=
                                                        900 &&
                                                        e.id_tipo_operacao <=
                                                            999 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhum deslocamento
                                                    cadastrado.
                                                </div>
                                            </div>
                                        </Option>
                                    )}
                                </OptGroup>
                                <OptGroup label="Tipo Parada">
                                    {haParad ? (
                                        filteredOpeOptions.map((e: any) => {
                                            return (
                                                <>
                                                    {e.id_tipo_operacao >=
                                                        800 &&
                                                        e.id_tipo_operacao <
                                                            900 && (
                                                            <Option
                                                                value={
                                                                    e.id_tipo_operacao
                                                                }
                                                                key={
                                                                    e.id_tipo_operacao
                                                                }
                                                            >
                                                                {e.descricao}
                                                            </Option>
                                                        )}
                                                </>
                                            );
                                        })
                                    ) : (
                                        <Option disabled>
                                            <div
                                                style={{
                                                    display: "flex",

                                                    flexDirection: "column",
                                                }}
                                            >
                                                {" "}
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        textOverflow:
                                                            "ellipsis",
                                                        overflow: "hidden",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    Não há nenhuma parada
                                                    cadastrada.
                                                </div>
                                            </div>
                                        </Option>
                                    )}

                                    <Option
                                        value="naoIdentificado"
                                        label="Não Identificado"
                                        key={uuidv4()}
                                    >
                                        <div
                                            style={{
                                                display: "flex",

                                                flexDirection: "column",
                                            }}
                                        >
                                            <div>Não Identificado</div>
                                        </div>
                                    </Option>
                                </OptGroup>{" "}
                            </>
                        ) : (
                            <Option disabled>
                                <div
                                    style={{
                                        display: "flex",

                                        flexDirection: "column",
                                    }}
                                >
                                    {" "}
                                    <div
                                        style={{
                                            flex: 1,
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                        }}
                                    >
                                        Não há nenhum tipo de op. cadastrada.
                                    </div>
                                </div>
                            </Option>
                        )}
                    </Select>
                </Form.Item>
            </Form>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        tipoOps: store.tiposOp,
        menuState: store.menu,
        processListState: store.processos,
        safras: store.safras,
        culturas: store.culturas,
        prefs: store.preferencia,
        loader: store.loader,
        time: store.time,
        tema: store.theme,
        equipamento: store.equipamento,

        tipoOpList: store.tiposOpCliente,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setTipoOp,
            setTipoOpParada,
            setCultura,
            setSafra,
            ativarProcess,
            setFocus,
            getEquipamentoListFiltro,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TipoOpController);
