import { Empty, Form, Select, Tooltip } from "antd";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    IFazendaList,
    IFazenda,
    IFazendaModule,
} from "../../reducers/FazendaReducer";
import { IPreferencia } from "../../reducers/PreferenciaReducer";
import { ITheme } from "../../reducers/ThemeReducer";
import { IStore } from "../../store";
import { setFocus } from "../../store/actions";
import { setFazenda, setTalhao } from "../../store/fazendaActions";
import { getFiltroTalhoes } from "../../store/talhaoActions";
const { Option } = Select;

interface FazendaControllerProps {
    fazendas: IFazendaList;
    fazenda: IFazenda;
    setFazenda: Function;
    setFocus: Function;
    prefs: IPreferencia;
    loader: any;
    tema: ITheme;

    setTalhao: Function;

    getFiltroTalhoes: Function;
}

function FazendaController(props: FazendaControllerProps) {
    const {
        fazendas,
        fazenda,
        setFazenda,
        setFocus,
        prefs,
        loader,
        getFiltroTalhoes,

        setTalhao,
    } = props;
    const [form] = Form.useForm();

    function handleChange(value: any) {
        setTalhao("all");
        setFazenda(fazendas[fazendas.findIndex(f => f.id_fazenda === value)]);

        setFocus({ view: 4, subView: null });
        getFiltroTalhoes(value);
    }

    useEffect(() => {
        if (loader.preferencia?.get.success) {
            if (fazenda.id_fazenda !== "") {
                setFazenda(fazenda);
                form.setFieldsValue({ nome: fazenda.nome });
            } else if (
                Object.keys(prefs).findIndex((p: any) => p === "faz") > -1
            ) {
                setFazenda(
                    fazendas[
                        fazendas.findIndex(
                            (f: IFazenda) => f.id_fazenda === prefs.faz
                        )
                    ]
                );
                form.setFieldsValue({ nome: prefs.faz });
            }
        }
    }, [prefs]);
    useEffect(() => {
        setTalhao("all");
        // getFiltroTalhoes(fazenda.id_fazenda);
    }, [fazenda]);

    // useEffect(() => {
    //     if (loader.loader?.get?.success) {
    //
    //         if (
    //             Object.keys(prefs.padrao).findIndex((p: any) => p === "faz") >
    //             -1
    //         ) {
    //
    //         }
    //     }
    // }, []);

    return (
        <Form
            form={form}
            layout="horizontal"
            // style={{
            //     padding: "4px",
            // }}
            className="tour-fazenda"
            // initialValues={{
            //     nome: fazenda.id_fazenda,
            // }}
        >
            <Form.Item
                label={
                    <span
                        className={
                            props.tema ? "icon-filtros-dark" : "icon-filtros"
                        }
                    >
                        <Tooltip title="Fazenda">
                            <i className="fas fa-warehouse"></i>
                        </Tooltip>
                    </span>
                }
                name="nome"
                colon={false}
            >
                <Select
                    style={{
                        width: 200,
                        color: props.tema ? "#fff" : "#606060",
                        border: "1px solid #C1C1C1",
                        backgroundColor: props.tema ? "#757575" : "",
                    }}
                    size="small"
                    optionLabelProp="label"
                    bordered={false}
                    placeholder="Selecione uma fazenda..."
                    suffixIcon={<i className="fas fa-chevron-right"></i>}
                    dropdownRender={menu => <div>{menu}</div>}
                    // dropdownStyle={{ zIndex: 1000 }}
                    notFoundContent={
                        <Empty
                            description="Nenhuma fazenda foi carregada."
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                    }
                    onChange={handleChange}
                    onSelect={handleChange}
                    dropdownClassName="agrochip-dropdown"
                >
                    {fazendas.map((f: any) => {
                        return (
                            <Option
                                value={f.id_fazenda}
                                label={"Fazenda " + f.nome}
                                key={f.id_fazenda}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <i
                                        className={f.icone}
                                        style={{
                                            fontSize: "20px",
                                            paddingRight: "10px",
                                        }}
                                    ></i>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            Fazenda {f.nome}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            {f.cidade}
                                        </div>
                                    </div>
                                </div>
                            </Option>
                        );
                    })}
                </Select>
            </Form.Item>
        </Form>
    );
}

function mapStateToProps(store: IStore) {
    return {
        fazenda: store.fazenda,
        fazendas: store.fazendas,
        prefs: store.preferencia,
        loader: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            setFazenda,
            setFocus,

            setTalhao,

            getFiltroTalhoes,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(FazendaController);
