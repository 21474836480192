import { Button, Modal, Popconfirm } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IOperadorList } from "../../../reducers/OperadorReducer";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStore } from "../../../store";
import { setStatusNull } from "../../../store/actions";
import { getOperador, deletarOperador } from "../../../store/operadorAction";
import { IStatusList } from "../../../store/types";
import AdicionarOperadores from "./Operadores/AdicionarOperadores";
import EditarOperador from "./Operadores/EditarOperador";

interface OperadoresProps {
    operadores: IOperadorList;
    loader: IStatusList;

    deletarOperador: Function;

    getOperador: Function;
    theme: ITheme;
}

function Operadores(props: OperadoresProps) {
    const { operadores, deletarOperador, getOperador, loader, theme } = props;
    const [modal, setModal] = useState(false);
    const [configModal, setConfigModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [which, setWhich] = useState(0);
    const [operador, setOperador] = useState("");
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    useMemo(() => {
        getOperador();
    }, []);

    function handleSelectOperador(value: any) {
        setOperador(value);
        setWhich(0);
    }

    function handleAdicionar() {
        setConfigModal({
            titulo: "Adicionar Operador",
            conteudo: <AdicionarOperadores setModal={setModal} />,
        });
        setModal(true);
    }

    function handleEditar(id: any) {
        const servKey = operadores.findIndex(
            (serv: any) => serv.id_operador === id
        );
        setConfigModal({
            titulo: "Editar Operador",
            conteudo: (
                <EditarOperador
                    cont={operadores[servKey]}
                    setModal={setModal}
                />
            ),
        });

        setModal(true);
    }

    function handleExcluir(id: any) {
        const servKey = operadores.findIndex(
            (serv: any) => serv.id_operador === id
        );
        setConfirmLoading(true);
        deletarOperador(operadores[servKey].id_operador, which);
    }

    const listaOperadores = useCallback(() => {
        return operadores;
    }, [operadores]);

    useMemo(() => {
        if (loader.operador?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
            setStatusNull("del", "operador");
        }
    }, [loader.operador?.del?.success]);

    return (
        <>
            <div style={{ height: "100%" }}>
                Operadores cadastrados:
                <div
                    style={{
                        display: "flex",
                        marginTop: "5px",
                        height: "calc(100% - 10px)",
                    }}
                >
                    <div
                        style={{
                            flex: 1,
                            paddingRight: "10px",
                            height: "100%",
                        }}
                    >
                        <ul
                            className={
                                theme
                                    ? "select-expand-ul-dark"
                                    : "select-expand-ul"
                            }
                        >
                            {listaOperadores().map((o: any) => {
                                let classN = theme
                                    ? "select-expand-li-dark"
                                    : "select-expand-li";
                                if (o.id_operador === which) {
                                    classN += " active";
                                }
                                return (
                                    <li
                                        className={classN}
                                        key={o.id_operador}
                                        value={o.id_operador}
                                        onClick={() => setWhich(o.id_operador)}
                                        onDoubleClick={() =>
                                            handleEditar(o.id_operador)
                                        }
                                    >
                                        {o.nome}
                                    </li>
                                );
                            })}
                            <li
                                style={{ flex: 1 }}
                                onClick={() => setWhich(0)}
                            ></li>
                        </ul>
                    </div>
                    <div
                        style={{
                            width: "100px",
                            display: "flex",
                            flexDirection: "column",
                            gap: "5px",
                        }}
                    >
                        <Button
                            type="primary"
                            onClick={() => handleAdicionar()}
                        >
                            Adicionar
                        </Button>
                        <Button
                            disabled={which === 0 ? true : false}
                            onClick={() => handleEditar(which)}
                        >
                            Editar
                        </Button>
                        <Popconfirm
                            placement="bottomRight"
                            visible={excluirVisible}
                            title="Você tem certeza que deseja excluir o servidor selecionado?"
                            onConfirm={() => handleExcluir(which)}
                            onCancel={() => setExcluirVisible(false)}
                            okButtonProps={{ loading: confirmLoading }}
                            okText="Sim"
                            cancelText="Não"
                            disabled={which === 0 ? true : false}
                        >
                            <Button
                                onClick={() => setExcluirVisible(true)}
                                style={{ width: "100%" }}
                                // disabled={which === 0 ? true : false}
                                disabled={true}
                            >
                                Excluir
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
            </div>
            <Modal
                title={configModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
            >
                {configModal.conteudo}
            </Modal>
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        operadores: store.operadores,
        loader: store.loader,
        theme: store.theme,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getOperador,
            deletarOperador,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Operadores);
