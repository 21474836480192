import { Button, Modal, Popconfirm } from "antd";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ITheme } from "../../../reducers/ThemeReducer";
import { IStore } from "../../../store";
import AdicionarImplemento from "./Implementos/AdicionarImplemento";
import EditarImplemento from "./Implementos/EditarImplemento";
import {
    deletarImplemento,
    getImplementos,
} from "../../../store/implementoActions";
import { IImplementoList } from "../../../reducers/ImplementoReducer";
import { IStatusList } from "../../../store/types";

export interface IImplementosProps {
    theme: ITheme;
    implementos: IImplementoList;
    estados: any[];
    tipos: any[];

    status: IStatusList;

    getImplementos: Function;
    deletarImplemento: Function;
}

function Implementos(props: IImplementosProps) {
    const {
        theme,
        implementos,
        estados,
        tipos,
        status,
        getImplementos,
        deletarImplemento,
    } = props;

    const [which, setWhich] = useState(0);
    const [modal, setModal] = useState(false);
    const [implementoModal, setImplementoModal] = useState({
        titulo: "",
        conteudo: {},
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [excluirVisible, setExcluirVisible] = useState(false);

    function handleAdicionarImplemento() {
        setImplementoModal({
            titulo: "Adicionar Implemento",
            conteudo: <AdicionarImplemento setModal={setModal} />,
        });
        setModal(true);
    }

    function handleEditarImplemento(id: any) {
        const indice = implementos.findIndex(
            (equ: any) => equ.id_implemento === id
        );
        setImplementoModal({
            titulo: "Editar implemento",
            conteudo: (
                <EditarImplemento
                    setModal={setModal}
                    cont={implementos[indice]}
                />
            ),
        });
        setModal(true);
    }

    function handleExcluirImplemento() {
        setConfirmLoading(true);
        deletarImplemento(which);
    }

    useMemo(() => {
        if (status.implemento?.del?.success) {
            setExcluirVisible(false);
            setConfirmLoading(false);
        }
        getImplementos();
    }, [status.implemento?.del?.success, getImplementos]);

    useEffect(() => {
        getImplementos();
    }, []);

    return (
        <div style={{ height: "100%" }}>
            Implementos cadastrados:
            <div
                style={{
                    display: "flex",
                    marginTop: "5px",
                    height: "calc(100% - 10px)",
                }}
            >
                <div
                    style={{
                        flex: 1,
                        paddingRight: "10px",
                        height: "100%",
                    }}
                >
                    <ul
                        className={
                            theme ? "select-expand-ul-dark" : "select-expand-ul"
                        }
                    >
                        {implementos.map((i: any) => {
                            let classN = theme
                                ? "select-expand-li-dark"
                                : "select-expand-li";
                            let estadokey = estados.findIndex(
                                (s: any) => s.value === i.estado
                            );
                            if (i.id_implemento === which) {
                                classN += " active";
                            }
                            return (
                                <li
                                    key={i.id_implemento}
                                    className={classN}
                                    onClick={() => setWhich(i.id_implemento)}
                                    onDoubleClick={() =>
                                        handleEditarImplemento(i.id_implemento)
                                    }
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "start",
                                        }}
                                    >
                                        {/* <div
                                            style={{
                                                fontSize: "25px",
                                                marginRight: "15px",
                                            }}
                                        >
                                            <i className={e.icone}></i>
                                        </div> */}
                                        <div>
                                            <div
                                                style={{
                                                    fontWeight: "bold",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {i.descricao}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: "12px",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {tipos[i.tipo - 1].nome}{" "}
                                                {i.numero_patrimonio}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                "status " +
                                                estados[estadokey].optionClass
                                            }
                                        >
                                            {estados[estadokey].displayName}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                        <li
                            style={{ flex: 1 }}
                            onClick={() => setWhich(0)}
                        ></li>
                    </ul>
                </div>
                <div
                    style={{
                        width: "100px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => handleAdicionarImplemento()}
                    >
                        Adicionar
                    </Button>
                    <Button
                        disabled={which === 0 ? true : false}
                        onClick={() => handleEditarImplemento(which)}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        placement="bottomRight"
                        visible={excluirVisible}
                        title="Você tem certeza que deseja excluir esse implemento?"
                        onConfirm={handleExcluirImplemento}
                        onCancel={() => setExcluirVisible(false)}
                        okButtonProps={{ loading: confirmLoading }}
                        okText="Sim"
                        cancelText="Não"
                        disabled={which === 0 ? true : false}
                    >
                        <Button
                            onClick={() => setExcluirVisible(true)}
                            style={{ width: "100%" }}
                            //disabled={which === 0 ? true : false}
                            disabled={true}
                        >
                            Excluir
                        </Button>
                    </Popconfirm>
                </div>
            </div>
            <Modal
                title={implementoModal.titulo}
                visible={modal}
                destroyOnClose={true}
                maskStyle={{ backdropFilter: "blur(5px)" }}
                onCancel={() => setModal(false)}
                footer={null}
                width={600}
                centered={true}
                zIndex={1025}
                bodyStyle={{
                    userSelect: "none",
                }}
            >
                {implementoModal.conteudo}
            </Modal>
        </div>
    );
}

function mapStateToProps(store: IStore) {
    return {
        theme: store.theme,
        implementos: store.implementos,
        estados: store.attrImplemento.estado,
        tipos: store.attrImplemento.tipos,
        status: store.loader,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            getImplementos,
            deletarImplemento,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Implementos);
