import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    MenuLeft,
    MenuTop,
    Logo,
    LeftContainer,
    RightContainer,
    TopContainer,
    BottomContainer,
    MenuLeftTopItem,
    MenuLeftBottomItem,
    SideWrapper,
    Spinner,
    Overview,
    TutorialContainer,
    TutorialBoasVindas,
} from "./styles/Main";
import { bindActionCreators } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import {
    ativarProcess,
    desativarProcess,
    loginProcess,
    loginProcessLogOut,
    setRefresh,
    setDashboardToggle,
    setFocus,
    getKeepAlive,
} from "./store/actions";

import {
    Button,
    Modal,
    Tooltip,
    Popover,
    Badge,
    Checkbox,
    Switch,
    Popconfirm,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { v4 as uuidv4 } from "uuid";

import Login from "./Login"; //
import Loader from "./Loader";
import Map from "./modules/map/Map";
import FazendaController from "./modules/controllers/FazendaController";
import TalhaoController from "./modules/controllers/TalhaoController";
import EquipamentoController from "./modules/controllers/EquipamentoController";
import FiltroController from "./modules/controllers/FiltroController";
import MensagemController from "./modules/controllers/MensagemController";
import MenuUser from "./modules/menu/MenuUser";
import { AuthState, IFoco, IMapRender, IStatusList } from "./store/types";
import { IStore } from "./store/index";
import TempoController from "./modules/controllers/TempoController";
import { IProcess, IProcessList } from "./reducers/ProcessReducer";
import { IMenu } from "./reducers/MenuReducer";
import { IFazenda } from "./reducers/FazendaReducer";
import { IEquipamento } from "./reducers/EquipamentoReducer";
import { ISafra } from "./reducers/SafraReducer";
import { ITipoOp } from "./reducers/TipoOpReducer";
import { ITime } from "./reducers/TimeReducer";
import { ICultura } from "./reducers/CulturaReducer";
import { getOperacao } from "./store/operacoesAction";
import { getPolyline, getEquipamentoListFiltro } from "./store/polylineAction";
import { IOperacoes } from "./reducers/OperacaoReducer";
import axios from "axios";
import { getCulturaCliente, setCultura } from "./store/culturaActions";
import { getEquipamentos, setEquipamento } from "./store/equipamentoActions";
import { getTipoOpCliente, setTipoOp } from "./store/tipoOpActions";
import { getSafraCliente, setSafra } from "./store/safraActions";
import { IPreferencia } from "./reducers/PreferenciaReducer";
import { editarPreferencia } from "./store/preferenciaActions";
import { IPolylineList } from "./reducers/PolylineReducer";
import Relatorios from "./modules/process/Relatorios";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { getRelatorio } from "./store/relatorioAction";
import { IRelatorio } from "./reducers/RelatorioReducer";
import Tour from "reactour";
import { IKeepAlive } from "./reducers/StatusReducer";
import KeepAlive from "./KeepAlive";
import { logoutReset } from "./store/userActions";
import Legenda from "./Legenda";
import ConexaoController from "./modules/controllers/ConexaoController";
import Teste from "./Teste";
import { ITheme } from "./reducers/ThemeReducer";
import { setTheme } from "./store/themeActions";
import { useThemeSwitcher } from "react-css-theme-switcher";
import TipoOpController from "./modules/controllers/TipoOpController";
import CulturaController from "./modules/controllers/CulturaController";
import SafraController from "./modules/controllers/SafraController";
import { getMensagem } from "./store/msgActions";
import { ITipoParadaList } from "./reducers/TipoParadaReducer";
import { getTipoParadas } from "./store/tipoParadaActions";
import { getAlertaRender, getAlertaUser } from "./store/alertaActions";
import OperadorController from "./modules/controllers/OperadorController";
import { IOperador } from "./reducers/OperadorReducer";
import { getFiltroTalhoes } from "./store/talhaoActions";
import { getImplementos } from "./store/implementoActions";

interface PropsApp {
    //GETTERS
    auth: AuthState;
    operacoes: IOperacoes;
    fazenda: IFazenda;
    equipamento: IEquipamento;
    safra: ISafra;
    tipoOp: ITipoOp;
    time: ITime;
    cultura: ICultura;
    loader: IStatusList;
    mapState: IMapRender;
    menuState: IMenu;
    processState: IProcess;
    processListState: IProcessList;
    prefs: IPreferencia;
    polylines: IPolylineList;
    dashboard: string;
    polylineFiltro: any;
    relatorio: IRelatorio;
    foco: IFoco;
    keepAlive: IKeepAlive;
    getEquipamentos: Function;
    theme: ITheme;
    operador: IOperador;

    talhao: any;

    tipoOpParada: any;

    //SETTERS
    ativarProcess: Function;
    desativarProcess: Function;
    loginProcessLogOut: Function;
    setRefresh: Function;
    getOperacao: Function;
    getPolyline: Function;
    getEquipamentoListFiltro: Function;
    getRelatorio: Function;
    getKeepAlive: Function;
    logoutReset: Function;
    setTheme: Function;
    getMensagem: Function;
    getTipoParadas: Function;
    tipoParadas: ITipoParadaList;
    getAlertaRender: Function;

    editarPreferencia: Function;
    setEquipamento: Function;
    setCultura: Function;
    setSafra: Function;
    setTipoOp: Function;
    setDashboardToggle: Function;
    setFocus: Function;

    getFiltroTalhoes: Function;

    getCulturaCliente: Function;
    getTipoOpCliente: Function;
    getSafraCliente: Function;

    getImplementos: Function;

    getAlertaUser: Function;
}

let timeout: any = null;
let timeoutMSG: any = null;

function App(props: PropsApp) {
    const {
        //GETTERS//
        auth,
        fazenda,
        equipamento,
        cultura,
        safra,
        time,
        tipoOp,
        loader,
        processState,
        processListState,
        mapState,
        menuState,
        prefs,
        dashboard,
        relatorio,
        keepAlive,
        getEquipamentos,
        getMensagem,
        getTipoParadas,
        tipoParadas,
        getAlertaRender,
        theme,
        operador,

        talhao,

        tipoOpParada,

        foco,

        //SETTERS
        ativarProcess,
        desativarProcess,
        loginProcessLogOut,
        setRefresh,
        getPolyline,
        getEquipamentoListFiltro,
        getRelatorio,
        getKeepAlive,
        logoutReset,
        setTheme,

        editarPreferencia,
        setEquipamento,
        setCultura,
        setSafra,
        setTipoOp,
        setDashboardToggle,
        setFocus,

        getFiltroTalhoes,

        getCulturaCliente,
        getTipoOpCliente,
        getSafraCliente,

        getImplementos,

        getAlertaUser,
    } = props;
    const [scroll, setScroll] = useState<any>(0);
    const [menuUserKey, setMenuUserKey] = useState(uuidv4());
    const [haAvatar, setHaAvatar] = useState(false);
    const [modalFiltros, setModalFiltros] = useState(false);
    const [whichModal, setWhichModal] = useState<any>(null);
    const [visibleOverview, setVisibleOverview] = useState(false);
    const [visibleTutorial, setVisibleTutorial] = useState(false);
    const [animaTutorial, setAnimaTutorial] = useState(true);
    const [realTime, setRealTime] = useState(false);
    const [realTimeMSG, setRealTimeMSG] = useState(false);
    const [equipaMSG, setEquipaMSG] = useState("");
    const [openTour, setOpenTour] = useState(false);
    const [tutorialChk, setTutorialChk] = useState(true);
    const [refreshLegenda, setRefreshLegenda] = useState("");

    const [desabilitaMenu, setDesabilitaMenu] = useState(
        window.screen.width < 920
    );
    const { switcher, currentTheme, status, themes } = useThemeSwitcher();

    function fScreen() {
        let elem: any = document.querySelector("html");

        if (!document.fullscreenElement) {
            elem.requestFullscreen().catch((err: any) => {
                alert(
                    "Algo de errado aconteceu e não conseguimos tornar a tela inteira."
                );
            });
        } else {
            document.exitFullscreen();
        }
    }
    const refScroll: any = useRef();

    const handleScroll = useCallback((e: any) => {
        setScroll(e.target.scrollTop);
    }, []);

    const toggleTheme = (isChecked: any) => {
        setTheme(isChecked);
        // switcher({ theme: isChecked ? themes.dark : themes.light });
    };
    useEffect(() => {
        if (prefs.tema !== undefined) {
            setTheme(prefs.tema);
        }
    }, [prefs.tema]);

    useEffect(() => {
        switcher({ theme: theme ? themes.dark : themes.light });
    }, [theme, loader.loader?.get?.success]); //

    useEffect(() => {
        if (prefs.faz !== undefined) {
            getFiltroTalhoes(prefs.faz);
        }
    }, [prefs.faz]);

    useEffect(() => {
        setInterval(() => {
            if (keepAlive) {
                getKeepAlive();
            }
        }, 10000);
    }, [keepAlive]);

    // useEffect(() => {
    //     ;
    // }, [relatorio]);

    useEffect(() => {
        if (auth.token.length > 0 && auth.expired === false) {
            axios({
                method: "get",
                url:
                    process.env.REACT_APP_BASE_URL_API +
                    "api/user/avatar/" +
                    auth.user.id,
            })
                .then(res => setHaAvatar(true))
                .catch(err => setHaAvatar(false));

            getCulturaCliente();
            getTipoOpCliente();
            getSafraCliente();

            getImplementos();

            getAlertaUser();
        }
    }, [auth]);

    useEffect(() => {
        if (fazenda.id_fazenda !== "") {
            getFiltroTalhoes(fazenda.id_fazenda);
        }
    }, [fazenda]);

    useEffect(() => {
        if (fazenda.id_fazenda !== "" && equipamento.id_equipamento !== "") {
            // getOperacao({
            //     faz: fazenda.id_fazenda,
            //     equipamento: equipamento.id_equipamento,
            //     safra: safra,
            //     tipo_operacao: tipoOp,
            //     cultura: cultura,
            //     dataIni: time.i.toISOString().split("T")[0],
            //     dataFin: time.f.toISOString().split("T")[0],
            // });
            //setInterval(() => {

            //condição antiga para aplicar filtro quando era parada
            // let condicao = false;
            // if (tipoOp.id_tipo_operacao !== undefined) {
            //     if (
            //         tipoOp.id_tipo_operacao === 0 ||
            //         (tipoOp.id_tipo_operacao >= 800 &&
            //             tipoOp.id_tipo_operacao < 900)
            //     ) {
            //         condicao = true;
            //     }
            // }

            getPolyline({
                faz: fazenda.id_fazenda,
                equip: equipamento.id_equipamento,
                safra: Object.keys(safra).length > 0 ? safra.id_safra : "",
                tipo_operacao:
                    Object.keys(tipoOp).length > 0
                        ? tipoOp.id_tipo_operacao
                        : "",
                tipo_parada:
                    Object.keys(tipoOpParada).length > 0
                        ? tipoOpParada.id_tipo_operacao
                        : "",
                cultura:
                    Object.keys(cultura).length > 0 ? cultura.id_cultura : "",
                operador:
                    Object.keys(operador).length > 0
                        ? operador.id_operador
                        : "",
                talhao: talhao !== "all" ? talhao : "",
                dataIni: time.i,
                dataFin: time.f,
                gpson: true,
                polyline: true,
            });
            //}, 10000);
        }
    }, [
        fazenda,
        equipamento.id_equipamento,
        safra,
        tipoOp,
        time,
        cultura,
        operador,
        talhao,
        tipoOpParada,
    ]); //

    useEffect(() => {
        if (fazenda.id_fazenda !== "" && equipamento.id_equipamento !== "") {
            getAlertaRender({
                faz: fazenda.id_fazenda,
                equip: equipamento.id_equipamento,
                safra: Object.keys(safra).length > 0 ? safra.id_safra : "",
                tipo_operacao:
                    Object.keys(tipoOp).length > 0
                        ? tipoOp.id_tipo_operacao
                        : "",
                tipo_parada:
                    Object.keys(tipoOpParada).length > 0
                        ? tipoOpParada.id_tipo_operacao
                        : "",
                cultura:
                    Object.keys(cultura).length > 0 ? cultura.id_cultura : "",
                talhao: talhao !== "all" ? talhao : "",
                operador:
                    Object.keys(operador).length > 0
                        ? operador.id_operador
                        : "",
                dataIni: time.i,
                dataFin: time.f,
                gpson: true,
                alerta: true,
            });
        }
    }, [
        fazenda,
        equipamento.id_equipamento,
        safra,
        tipoOp,
        tipoOpParada,
        time,
        cultura,
        talhao,
        operador,
    ]);

    useEffect(() => {}, [relatorio]);

    useEffect(() => {
        if (fazenda.id_fazenda !== "") {
            getRelatorio({
                faz: fazenda.id_fazenda,
                dataIni: time.i,
                dataFin: time.f,
                //equip: equipamento.id_equipamento,
                tipo_operacao:
                    tipoOp.id_tipo_operacao !== undefined
                        ? tipoOp.id_tipo_operacao
                        : "",
                tipo_parada:
                    Object.keys(tipoOpParada).length > 0
                        ? tipoOpParada.id_tipo_operacao
                        : "",
                safra: safra.id_safra !== undefined ? safra.id_safra : "",
                cultura:
                    cultura.id_cultura !== undefined ? cultura.id_cultura : "",
                gpson: true,
                operador:
                    Object.keys(operador).length > 0
                        ? operador.id_operador
                        : "",
                talhao: talhao !== "all" ? talhao : "",
            });
        }
    }, [fazenda, safra, tipoOp, tipoOpParada, time, cultura, operador, talhao]);

    useEffect(() => {
        if (
            fazenda.id_fazenda !== "" &&
            (foco.view === 7 || dashboard === "dashboard")
        ) {
            // setInterval(() => {

            //condição antiga para aplicar filtro quando era parada
            // let condicao = false;
            // if (tipoOp.id_tipo_operacao !== undefined) {
            //     if (
            //         tipoOp.id_tipo_operacao === 0 ||
            //         (tipoOp.id_tipo_operacao >= 800 &&
            //             tipoOp.id_tipo_operacao < 900)
            //     ) {
            //         condicao = true;
            //     }
            // }

            getEquipamentoListFiltro({
                faz: fazenda.id_fazenda,
                tipo_operacao:
                    tipoOp.id_tipo_operacao !== undefined
                        ? tipoOp.id_tipo_operacao
                        : "",
                tipo_parada:
                    Object.keys(tipoOpParada).length > 0
                        ? tipoOpParada.id_tipo_operacao
                        : "",
                safra: safra.id_safra !== undefined ? safra.id_safra : "",
                cultura:
                    cultura.id_cultura !== undefined ? cultura.id_cultura : "",
                dataIni: time.i,
                dataFin: time.f,
                gpson: true,
                polyline: true,
                operador:
                    Object.keys(operador).length > 0
                        ? operador.id_operador
                        : "",
                talhao: talhao !== "all" ? talhao : "",
            });
            // }, 10000)
        }
    }, [tipoOp, time, fazenda, safra, cultura, operador, talhao, tipoOpParada]);

    useEffect(() => {}, [tutorialChk]);

    useEffect(() => {
        if (loader.loader?.get?.success) {
            if (Object.keys(prefs).findIndex((p: any) => p === "safra") > -1) {
                setSafra(prefs.safra);
            }
            if (
                Object.keys(prefs).findIndex((p: any) => p === "cultura") > -1
            ) {
                setCultura(prefs.cultura);
            }
            if (Object.keys(prefs).findIndex((p: any) => p === "tipoOp") > -1) {
                setTipoOp(prefs.tipoOp);
            }
        }
    }, [loader]);

    useEffect(() => {
        return () => clearInterval(timeout);
    }, []);

    useEffect(() => {
        if (realTime === true) {
            timeout = setInterval(() => {
                getEquipamentos();
            }, 5000);
        }
    }, [realTime]);

    useEffect(() => {
        if (realTime !== true) {
            clearInterval(timeout);
        }
    }, [realTime]);

    // useEffect(() => {
    //     return () => clearInterval(timeoutMSG);
    // }, []);
    useEffect(() => {
        return () => clearInterval(timeoutMSG);
    }, [equipaMSG]);

    useEffect(() => {
        if (realTimeMSG === true && equipaMSG !== "") {
            timeoutMSG = setInterval(() => {
                getMensagem(equipaMSG);
            }, 15000);
        }
    }, [equipaMSG, realTimeMSG]);

    useEffect(() => {
        if (realTimeMSG !== true) {
            clearInterval(timeoutMSG);
        }
    }, [realTimeMSG]);

    useEffect(() => {
        if (localStorage.getItem("tutorial") === "show") {
            setVisibleTutorial(true);
        }
    }, [localStorage.getItem("tutorial")]);

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.screen.width < 920) {
                setDesabilitaMenu(true);
            } else setDesabilitaMenu(false);
        });
        window.addEventListener("focus", () => {
            if (window.screen.width < 920) {
                setRealTime(false);
            }
        });
    });

    if (auth.token.length < 1) {
        return <Login />;
    } else {
        return (
            //
            <>
                {loader.loader?.get?.success === "" ? ( ////
                    <Loader />
                ) : (
                    <>
                        {/* <Teste view /> */}
                        {realTime ? (
                            <>
                                <div
                                    style={{
                                        zIndex: 1999,
                                        position: "absolute",
                                        left: 0,
                                        top: 40,
                                        bottom: 0,
                                        right: 0,
                                    }}
                                ></div>
                                <div
                                    style={{
                                        zIndex: 1999,
                                        position: "absolute",
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        right: 257,
                                    }}
                                ></div>
                                <div
                                    style={{
                                        zIndex: 1999,
                                        background: "rgba(247, 247, 243, 0.3)",
                                        position: "absolute",
                                        left: 40,
                                        top: 0,
                                        bottom: "calc(100% - 40px)",
                                        right: 257,
                                        backdropFilter: "blur(3px)",
                                    }}
                                ></div>
                                <div
                                    style={{
                                        zIndex: 1999,
                                        position: "absolute",
                                        background: "rgba(76, 78, 69, 0.6)",
                                        left: 0,
                                        top: 40,
                                        bottom: 0,
                                        right: "calc(100% - 40px)",
                                        backdropFilter: "blur(3px)",
                                    }}
                                ></div>
                            </>
                        ) : (
                            <></>
                        )}
                        {visibleTutorial && !desabilitaMenu && (
                            <>
                                <Tour
                                    CustomHelper={(x: any) => {
                                        return (
                                            <div
                                                style={{
                                                    background: theme
                                                        ? "#424242"
                                                        : "#FFF",
                                                    boxShadow:
                                                        "5px 5px 5px rgba(0, 0, 0, 0.5)",
                                                    width: "300px",
                                                    height: "200px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    padding: "30px",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        top: "0",
                                                        width: "100%",
                                                        zIndex: "2500",
                                                        display: "flex",
                                                        justifyContent:
                                                            "flex-end",
                                                        fontSize: "20px",
                                                        padding: "5px 10px",
                                                        color: theme
                                                            ? "#FFF"
                                                            : "rgba(47, 53, 126, 1)",
                                                    }}
                                                >
                                                    <i
                                                        className="fas fa-times"
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => {
                                                            setOpenTour(false);
                                                            setVisibleTutorial(
                                                                false
                                                            );
                                                        }}
                                                    ></i>
                                                </div>
                                                <div>{x.content}</div>
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        bottom: "0",
                                                        width: "100%",
                                                        zIndex: "2500",
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                        fontSize: "50px",
                                                        marginBottom: "-25px",
                                                        padding: "0 20px",
                                                        color: "rgba(47, 53, 126, 1)",
                                                    }}
                                                >
                                                    <i
                                                        className="fas fa-chevron-circle-left"
                                                        style={{
                                                            background: "#FFF",
                                                            borderRadius: "50%",
                                                            border: "1px solid rgba(47, 53, 126, 1)",
                                                            cursor: "pointer",
                                                            visibility:
                                                                x.current ===
                                                                    0 ||
                                                                x.current ===
                                                                    x.totalSteps -
                                                                        1
                                                                    ? "hidden"
                                                                    : "visible",
                                                        }}
                                                        onClick={() => {
                                                            x.gotoStep(
                                                                x.current - 1
                                                            );
                                                        }}
                                                    ></i>
                                                    <div
                                                        style={{
                                                            background:
                                                                "rgba(47, 53, 126, 1)",
                                                            fontSize: "20px",
                                                            color: "#FFF",
                                                            borderRadius:
                                                                "25px",
                                                            height: "50px",
                                                            display: "flex",
                                                            justifyContent:
                                                                "center",
                                                            alignItems:
                                                                "center",
                                                            visibility:
                                                                x.current ===
                                                                x.totalSteps - 1
                                                                    ? "visible"
                                                                    : "hidden",
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                background:
                                                                    "rgba(47, 53, 126, 1)",
                                                                borderRadius:
                                                                    "25px",
                                                                border: "1px solid #FFF",
                                                                height: "48px",
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                                alignItems:
                                                                    "center", //
                                                                padding:
                                                                    "0 15px",
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => {
                                                                setOpenTour(
                                                                    false
                                                                );
                                                                setVisibleTutorial(
                                                                    false
                                                                );
                                                                let prefs_temp =
                                                                    JSON.parse(
                                                                        JSON.stringify(
                                                                            prefs
                                                                        )
                                                                    );
                                                                prefs_temp[
                                                                    "tutorial"
                                                                ] = tutorialChk;

                                                                editarPreferencia(
                                                                    {
                                                                        preferencia:
                                                                            JSON.stringify(
                                                                                prefs_temp
                                                                            ),
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            Concluir
                                                        </div>
                                                    </div>
                                                    <i
                                                        className="fas fa-chevron-circle-right"
                                                        style={{
                                                            background: "#FFF",
                                                            borderRadius: "50%",
                                                            border: "1px solid rgba(47, 53, 126, 1)",
                                                            cursor: "pointer",
                                                            visibility:
                                                                x.current ===
                                                                x.totalSteps - 1
                                                                    ? "hidden"
                                                                    : "visible",
                                                        }}
                                                        onClick={() => {
                                                            x.gotoStep(
                                                                x.current + 1
                                                            );
                                                        }}
                                                    ></i>
                                                </div>
                                            </div>
                                        );
                                    }}
                                    // className="tutorial-helper"
                                    accentColor="rgba(47, 53, 126, 1)"
                                    steps={[
                                        {
                                            selector: ".tour-tempo",
                                            content: (
                                                <div>
                                                    Antes de tudo, é necessário
                                                    selecionar um intervalo de
                                                    tempo para começar. Por
                                                    padrão, esse componente está
                                                    configurado com a data de
                                                    hoje, do início do dia até a
                                                    hora atual.
                                                </div>
                                            ),
                                        },
                                        {
                                            selector: ".tour-fazenda",
                                            content:
                                                "Posteriormente, escolha uma fazenda. Todas as opções de filtragem da Agrochip dependem da escolha da fazenda.",
                                        },
                                        {
                                            selector: ".tour-talhao",
                                            content:
                                                "Ao escolher uma fazenda, você terá acesso a sua lista de talhões. Essa opção não é obrigatória para filtragem.",
                                        },
                                        {
                                            selector: ".tour-equipamento",
                                            content:
                                                "Aqui estarão listados os equipamentos cadastrados. Caso selecione algum, poderá visualizar os seus dados operacionais, estatísticas e sua localização atual.",
                                        },
                                        {
                                            selector: ".tour-filtro",
                                            content:
                                                "Encontre, clicando nesse botão, opções de filtragem por: Tipo de Operação, Cultura, Safra ou Operador.",
                                        },
                                        {
                                            selector: ".tour-real",
                                            content:
                                                "Ative aqui a funcionalidade de atualização de equipamentos em tempo real.",
                                        },
                                        {
                                            selector: ".tour-msg",
                                            content:
                                                "Nesse item, você poderá enviar mensagens diretamente para o operador do equipamento correspondente.",
                                        },
                                        {
                                            selector: ".tour-conexao",
                                            content:
                                                "Verifique a conectividade da aplicação por meio desse ícone.",
                                        },
                                        {
                                            selector: ".tour-user",
                                            content:
                                                "Aqui, você visualiza suas informações e ações como usuário.",
                                        },
                                        {
                                            selector: ".tour-theme",
                                            content:
                                                "Você pode mudar o tema da Agrochip por via desse botão.",
                                        },
                                        {
                                            selector: ".tour-fs",
                                            content:
                                                "Você pode mudar para o modo de Tela Cheia por esse item.",
                                        },
                                        {
                                            selector: ".tour-toggle",
                                            content: (
                                                <div>
                                                    A Agrochip é dividida em
                                                    duas formas de visualizar o
                                                    conteúdo.
                                                </div>
                                            ),
                                        },
                                        {
                                            selector: ".tour-toggle-a",
                                            content: (
                                                <div>
                                                    No modo{" "}
                                                    <strong>Mapa</strong> você
                                                    terá acesso a informações de
                                                    geolocalização, trajetos e
                                                    produção de uma forma mais
                                                    visual.
                                                </div>
                                            ),
                                        },
                                        {
                                            selector: ".tour-toggle-b",
                                            content: (
                                                <div>
                                                    E no modo{" "}
                                                    <strong>Dashboard</strong>{" "}
                                                    estarão listados os gráficos
                                                    estatísticos das operações,
                                                    tudo conforme os filtros que
                                                    você escolheu previamente.
                                                </div>
                                            ),
                                        },
                                        {
                                            selector: ".tour-opera",
                                            content:
                                                "Por aqui, você pode gerar tabelas personalizadas dos dados operacionais.",
                                        },
                                        {
                                            selector: ".tour-config",
                                            content:
                                                "Nesse item, você terá acesso ao gerenciamento das fazendas, operadores, equipamentos, servidores e alertas cadastrados no nosso sistema. Você poderá também personalizar as definições de inicialização e visualização.",
                                        },
                                        {
                                            selector: ".tour-nothing",
                                            content: (x: any) => {
                                                return (
                                                    <div>
                                                        <p>
                                                            Agora você está
                                                            pronto para começar
                                                            a utilizar a
                                                            plataforma Agrochip.
                                                        </p>
                                                        <p>
                                                            Caso tenha alguma
                                                            dúvida em relação a
                                                            usabilidade da
                                                            aplicação, nossa
                                                            equipe está a
                                                            disposição para
                                                            ajudar.
                                                        </p>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    onRequestClose={(e: any) => {
                                        if (e.target.nodeName !== "rect") {
                                            setOpenTour(false);
                                        }
                                    }}
                                    disableInteraction={true}
                                    isOpen={openTour}
                                    highlightedMaskClassName="tutorial-mask"
                                />
                                <TutorialContainer show={!openTour}>
                                    <TutorialBoasVindas fade={animaTutorial}>
                                        <div>
                                            <img
                                                src="/logo2.svg"
                                                alt="Agrochip"
                                                style={{ width: "100%" }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                                paddingTop: "10px",
                                                width: "100%",
                                                borderTop: "2px solid #FFF",
                                                fontSize: "20px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Seja bem vindo ao tutorial de
                                            primeiros passos da Agrochip. A
                                            intenção é orientar você quanto as
                                            sessões e a forma como a nossa
                                            plataforma funciona.
                                        </div>
                                        <div
                                            style={{
                                                marginTop: "50px",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "bottom",
                                            }}
                                        >
                                            <div>
                                                <Checkbox
                                                    onChange={() =>
                                                        setTutorialChk(
                                                            !tutorialChk
                                                        )
                                                    }
                                                    style={{ color: "#FFF" }}
                                                >
                                                    Não ver novamente
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Button
                                                    type="dashed"
                                                    style={{
                                                        color: "#FFF",
                                                        background:
                                                            "transparent",
                                                        marginRight: "10px",
                                                    }}
                                                    onClick={() => {
                                                        setVisibleTutorial(
                                                            false
                                                        );
                                                        localStorage.setItem(
                                                            "tutorial",
                                                            "hide"
                                                        );
                                                        let prefs_temp =
                                                            JSON.parse(
                                                                JSON.stringify(
                                                                    prefs
                                                                )
                                                            );
                                                        prefs_temp["tutorial"] =
                                                            tutorialChk;

                                                        editarPreferencia({
                                                            preferencia:
                                                                JSON.stringify(
                                                                    prefs_temp
                                                                ),
                                                        });
                                                    }}
                                                >
                                                    Sair
                                                </Button>
                                                <Button
                                                    type="ghost"
                                                    style={{ color: "#FFF" }}
                                                    onClick={() => {
                                                        setAnimaTutorial(false);
                                                        setTimeout(() => {
                                                            // setVisibleTutorial(
                                                            //     false
                                                            // );
                                                            setOpenTour(true);
                                                            // setAnimaTutorial(
                                                            //     false
                                                            // );
                                                        }, 1000);
                                                        let prefs_temp =
                                                            JSON.parse(
                                                                JSON.stringify(
                                                                    prefs
                                                                )
                                                            );
                                                        prefs_temp["tutorial"] =
                                                            tutorialChk;

                                                        editarPreferencia({
                                                            preferencia:
                                                                JSON.stringify(
                                                                    prefs_temp
                                                                ),
                                                        });
                                                    }}
                                                >
                                                    Começar
                                                </Button>
                                            </div>
                                        </div>
                                    </TutorialBoasVindas>
                                </TutorialContainer>{" "}
                            </>
                        )}
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                backdropFilter: "blur(3px)",
                                zIndex: 1031,
                                display:
                                    loader.filtros?.get?.loading ||
                                    // loader.relatorio?.get?.loading ||
                                    loader.pdf?.get?.loading ||
                                    loader.talhao_tablet?.add?.loading ||
                                    loader.talhao_tablet?.get?.loading
                                        ? "flex"
                                        : "none", //
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Spinner view={true}>
                                <i
                                    className="fas fa-spinner"
                                    style={{ fontSize: "50px", color: "#FFF" }}
                                ></i>
                            </Spinner>
                        </div>
                        <MenuTop dark={theme}>
                            <LeftContainer>
                                <Popconfirm
                                    placement="bottomLeft"
                                    title={
                                        <div>
                                            Podem ter filtros aplicados,
                                            <br />
                                            devido a atualização você perderá
                                            <br />
                                            esses filtros. Quer atualizar?
                                        </div>
                                    }
                                    okText="Sim"
                                    cancelText="Não"
                                    onConfirm={() => {
                                        window.location.reload();
                                    }}
                                >
                                    <Logo style={{ cursor: "pointer" }}>
                                        <img
                                            src={
                                                process.env
                                                    .REACT_APP_BASE_URL_ROOT +
                                                "logo.svg"
                                            }
                                            alt="Agrochip"
                                        />
                                    </Logo>
                                </Popconfirm>
                                <div className="tempo-controller">
                                    <TempoController tema={theme} />
                                </div>
                                {/* <Popover
                                    placement="bottomRight"
                                    trigger="click"
                                    content={
                                        <Tooltip
                                            placement="right"
                                            title={
                                                <>
                                                    <div
                                                        style={{
                                                            fontWeight:
                                                                "bolder",
                                                        }}
                                                    >
                                                        Escolha um intervalo de
                                                        tempo
                                                    </div>
                                                    <div>
                                                        Caso não escolha, a
                                                        aplicação exibirá
                                                        operações da data atual.
                                                    </div>
                                                </>
                                            }
                                            visible={visibleTooltip}
                                            overlayStyle={{
                                                marginLeft: "25px",
                                            }}
                                        >
                                            <TempoController
                                                setVisibleTempo={
                                                    setVisibleTempo
                                                }
                                                setVisibleTooltip={
                                                    setVisibleTooltip
                                                }
                                            />
                                        </Tooltip>
                                    }
                                    visible={visibleTempo}
                                >
                                    <Tooltip
                                        placement="bottom"
                                        title="Tempo"
                                        key={563}
                                        mouseEnterDelay={0}
                                    >
                                        <div
                                            style={{
                                                display: "inline-flex",
                                                flexDirection: "row",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                marginLeft: "10px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                setVisibleTempo(true)
                                            }
                                        >
                                            <i
                                                className="far fa-calendar-alt"
                                                style={{
                                                    color: "#606060",
                                                    fontSize: "18px",
                                                    marginTop: "-3px",
                                                }}
                                            ></i>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    marginLeft: "10px",
                                                    marginRight: "8px",
                                                    color: "rgb(96, 96, 96)",
                                                    border: "1px solid rgb(193, 193, 193)",
                                                    height: "26px",
                                                    padding: "0 7px 0 7px",
                                                }}
                                            >
                                                {("0" + time.i.getDate()).slice(
                                                    -2
                                                )}
                                                /
                                                {(
                                                    "0" +
                                                    (time.i.getMonth() + 1)
                                                ).slice(-2)}
                                                /{time.i.getFullYear()} -{" "}
                                                {("0" + time.f.getDate()).slice(
                                                    -2
                                                )}
                                                /
                                                {(
                                                    "0" +
                                                    (time.f.getMonth() + 1)
                                                ).slice(-2)}
                                                /{time.f.getFullYear()}
                                            </div>
                                        </div>
                                    </Tooltip>
                                </Popover> */}
                                <div className="fazenda-controller">
                                    <FazendaController tema={theme} />
                                </div>
                                <div className="talhao-controller">
                                    <TalhaoController tema={theme} />
                                </div>
                                <div className="equipamento-controller">
                                    <EquipamentoController tema={theme} />
                                </div>
                                <div className="tipoOp-controller">
                                    <TipoOpController />
                                </div>
                                <div className="cultura-controller">
                                    <CulturaController />
                                </div>
                                <div className="safra-controller">
                                    <SafraController />
                                </div>
                                <div className="operador-controller">
                                    <OperadorController />
                                </div>

                                <div
                                    className="btn-filtroController"
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        margin: "5px",
                                    }}
                                >
                                    <Popover
                                        trigger="click"
                                        placement="bottom"
                                        content={
                                            <>
                                                <FiltroController
                                                    tema={theme}
                                                />
                                            </>
                                        }
                                    >
                                        <Button
                                            type="ghost"
                                            size="small"
                                            disabled={fazenda.id_fazenda === ""}
                                            style={{
                                                display: "flex",
                                                fontSize: "25px",
                                                alignItems: "flex-end",
                                            }}
                                            className="tour-filtro "
                                        >
                                            <div
                                                style={{
                                                    marginTop: "-15px",
                                                }}
                                            >
                                                ...
                                            </div>
                                        </Button>
                                    </Popover>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        margin: "5px",
                                    }}
                                >
                                    <Spinner
                                        view={loader.operacao?.get?.loading}
                                    >
                                        <i className="fas fa-spinner"></i>
                                    </Spinner>
                                </div>
                            </LeftContainer>
                            <RightContainer key={menuUserKey} tema={theme}>
                                <Tooltip
                                    title="Visualização em tempo real"
                                    placement="bottom"
                                >
                                    {realTime ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "base-line",
                                                paddingLeft: "0px",
                                            }}
                                            className="tour-real"
                                        >
                                            <Spinner view={true}>
                                                <i
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "base-line",
                                                        paddingLeft: "0px",
                                                    }}
                                                    className="fas fa-sync"
                                                    onClick={() => {
                                                        setFocus({
                                                            view: 4,
                                                            subview: null,
                                                        });
                                                        desativarProcess(
                                                            processState.side
                                                        );
                                                        setEquipamento({
                                                            id_equipamento: "",
                                                            descricao: "",
                                                            numero_patrimonio:
                                                                "",
                                                            tipo: "",
                                                            estado: "",
                                                            device_id: "",
                                                            serial: "",
                                                            ultima_operacao: {
                                                                timestamp: "",
                                                            },
                                                        });
                                                        setDashboardToggle(
                                                            "mapa"
                                                        );
                                                        setRealTime(!realTime);
                                                    }}
                                                ></i>
                                            </Spinner>
                                        </div>
                                    ) : (
                                        <i
                                            className="fas fa-sync tour-real"
                                            onClick={() => {
                                                setFocus({
                                                    view: 4,
                                                    subview: null,
                                                });
                                                desativarProcess(
                                                    processState.side
                                                );
                                                setEquipamento({
                                                    id_equipamento: "",
                                                    descricao: "",
                                                    numero_patrimonio: "",
                                                    tipo: "",
                                                    estado: "",
                                                    device_id: "",
                                                    serial: "",
                                                    ultima_operacao: {
                                                        timestamp: "",
                                                    },
                                                });
                                                setDashboardToggle("mapa");
                                                setRealTime(!realTime);
                                            }}
                                        ></i>
                                    )}
                                </Tooltip>

                                <Popover
                                    onVisibleChange={v => setRealTimeMSG(v)}
                                    placement="bottomRight"
                                    trigger="click"
                                    content={
                                        <MensagemController
                                            setEquipaMSG={setEquipaMSG}
                                        />
                                    }
                                    visible={false}
                                >
                                    <Badge
                                        //dot={msgNotify}
                                        style={{
                                            width: "8px",
                                            height: "8px",
                                        }}
                                        offset={[-2, 2]}
                                    >
                                        <i
                                            style={{
                                                fontSize: "25px",
                                                display: "none",
                                            }}
                                            className="fas fa-comment tour-msg"
                                        ></i>
                                    </Badge>
                                </Popover>
                                <ConexaoController tema={theme} />
                                <Popover
                                    placement={
                                        desabilitaMenu ? "bottom" : "bottomLeft"
                                    }
                                    trigger="click"
                                    className="tour-user"
                                    overlayClassName="tour-user"
                                    content={
                                        <MenuUser
                                            authState={auth}
                                            setRefresh={setRefresh}
                                            setMenuUserKey={setMenuUserKey}
                                            key={menuUserKey}
                                        />
                                    }
                                >
                                    {haAvatar ? (
                                        <img
                                            style={{
                                                width: "24px",
                                                height: "24px",
                                                borderRadius: "50%",
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                                cursor: "pointer",
                                                border: "1px solid #FFF",
                                            }}
                                            src={
                                                process.env
                                                    .REACT_APP_BASE_URL_API +
                                                "api/user/avatar/" +
                                                auth.user.id
                                            }
                                            alt="não carregou"
                                        />
                                    ) : (
                                        <i
                                            className="fas fa-user-circle"
                                            style={{ marginRight: "10px" }}
                                        ></i>
                                    )}
                                </Popover>
                                <div style={{ marginTop: "-6px" }}>
                                    <Tooltip
                                        title="Dark Mode"
                                        placement="bottom"
                                    >
                                        <Switch
                                            className="tour-theme"
                                            checkedChildren={
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    🌜
                                                </span>
                                            }
                                            unCheckedChildren={
                                                <span
                                                    style={{ fontSize: "17px" }}
                                                >
                                                    🌞
                                                </span>
                                            }
                                            defaultChecked={theme}
                                            onChange={toggleTheme}
                                            checked={theme}
                                        />
                                    </Tooltip>
                                </div>

                                <i
                                    style={{
                                        display: desabilitaMenu ? "none" : "",
                                    }}
                                    className="fas fa-expand tour-fs"
                                    onClick={() => fScreen()}
                                ></i>
                            </RightContainer>
                        </MenuTop>
                        <MenuLeft tema={theme}>
                            <TopContainer>
                                <ToggleButtonGroup
                                    disabled={desabilitaMenu}
                                    orientation="vertical"
                                    className="tour-toggle"
                                    value={dashboard}
                                    exclusive
                                    onChange={(i: any, y: any) => {
                                        if (y !== null) {
                                            setDashboardToggle(y);
                                            setScroll(0);
                                            if (y === "dashboard") {
                                                desativarProcess(
                                                    processState.side
                                                );
                                            }
                                            if (y === "mapa") {
                                                setFocus({
                                                    view: 4,
                                                    subview: null,
                                                });
                                            }
                                        }
                                    }}
                                >
                                    <ToggleButton
                                        value="mapa"
                                        aria-label="mapa"
                                        className="tour-toggle-a"
                                    >
                                        {" "}
                                        <i
                                            className="fas fa-map-marked-alt"
                                            style={
                                                desabilitaMenu
                                                    ? {
                                                          opacity: 0.3,
                                                          cursor: "not-allowed",
                                                      }
                                                    : {}
                                            }
                                        ></i>
                                    </ToggleButton>
                                    <ToggleButton
                                        value="dashboard"
                                        aria-label="dashboard"
                                        className="tour-toggle-b"
                                    >
                                        <i
                                            className="far fa-chart-bar"
                                            style={
                                                desabilitaMenu
                                                    ? {
                                                          opacity: 0.3,
                                                          cursor: "not-allowed",
                                                      }
                                                    : {}
                                            }
                                        ></i>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <div
                                    style={{
                                        borderBottom: "1px solid #1e2252",
                                        marginBottom: "10px",
                                        marginTop: "10px",
                                    }}
                                ></div>
                                {menuState.map((m: any) => {
                                    let classMenu = m.class;
                                    if (m.id === 5) {
                                        classMenu = "tour-opera " + m.class;
                                    }
                                    if (m.position === "top") {
                                        return (
                                            <Tooltip
                                                placement="right"
                                                title={m.name}
                                                key={m.id}
                                                mouseEnterDelay={0}
                                                overlayInnerStyle={{
                                                    marginTop: "-5px",
                                                }}
                                            >
                                                <MenuLeftTopItem
                                                    ativo={
                                                        processState.side
                                                            ?.id ===
                                                            m.process &&
                                                        processState.side
                                                            ?.ativo === true
                                                    }
                                                >
                                                    <i
                                                        className={classMenu}
                                                        style={
                                                            m.enable ===
                                                                false ||
                                                            fazenda.id_fazenda ===
                                                                "" ||
                                                            desabilitaMenu
                                                                ? {
                                                                      opacity: 0.3,
                                                                      cursor: "not-allowed",
                                                                  }
                                                                : {}
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                fazenda.id_fazenda !==
                                                                ""
                                                            ) {
                                                                if (
                                                                    desabilitaMenu
                                                                ) {
                                                                } else if (
                                                                    m.enable
                                                                ) {
                                                                    switch (
                                                                        m.id
                                                                    ) {
                                                                        case 1:
                                                                            setDashboardToggle(
                                                                                "mapa"
                                                                            );
                                                                            break;
                                                                        case 2:
                                                                            setDashboardToggle(
                                                                                "mapa"
                                                                            );
                                                                            break;
                                                                        // case 6:
                                                                        //     setDashboardToggle(
                                                                        //         "mapa"
                                                                        //     );
                                                                        //     break;
                                                                    }
                                                                    // setDashboardToggle(
                                                                    //     "mapa"
                                                                    // );
                                                                    ativarProcess(
                                                                        {
                                                                            menuItem:
                                                                                m,
                                                                            processList:
                                                                                processListState,
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                    ></i>
                                                </MenuLeftTopItem>
                                            </Tooltip>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </TopContainer>
                            <BottomContainer>
                                {menuState.map((m: any) => {
                                    if (m.position === "bottom") {
                                        let classMenu = m.class;
                                        if (m.id === 4) {
                                            classMenu =
                                                "tour-config " + m.class;
                                        }
                                        return (
                                            <Tooltip
                                                placement="right"
                                                title={m.name}
                                                key={m.id}
                                                mouseEnterDelay={0}
                                                overlayInnerStyle={{
                                                    marginTop: "18px",
                                                }}
                                            >
                                                <MenuLeftBottomItem
                                                    ativo={m.active}
                                                >
                                                    <i
                                                        className={classMenu}
                                                        style={
                                                            desabilitaMenu
                                                                ? {
                                                                      opacity: 0.3,
                                                                      cursor: "not-allowed",
                                                                  }
                                                                : {}
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                desabilitaMenu
                                                            ) {
                                                            } else {
                                                                ativarProcess({
                                                                    menuItem: m,
                                                                    processList:
                                                                        processListState,
                                                                });
                                                            }
                                                        }}
                                                    ></i>
                                                </MenuLeftBottomItem>
                                            </Tooltip>
                                        );
                                    } else {
                                        return null;
                                    }
                                })}
                            </BottomContainer>
                        </MenuLeft>
                        {processState.side?.ativo && (
                            <>
                                <div
                                    style={{
                                        overflow: "hidden",
                                        position: "absolute",
                                        right: 0,
                                        top: "40px",
                                        height: "calc(100vh - 40px)",
                                        width: "400px",
                                    }}
                                >
                                    <SideWrapper
                                        dplay={processState.side?.ativo}
                                    >
                                        <div
                                            style={{
                                                color: "#FFFFFF",
                                                padding: "5px 10px 5px 20px",
                                                fontSize: "15px",
                                                fontWeight: "bold",
                                                borderBottom: "1px solid #FFF",
                                                textAlign: "right",
                                            }}
                                        >
                                            {processState.side?.nome}
                                            <i
                                                className="fas fa-angle-double-right"
                                                style={{
                                                    marginLeft: "10px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    desativarProcess(
                                                        processState.side
                                                    )
                                                }
                                            ></i>
                                        </div>
                                        {processState.side?.exe}
                                        <div className="logo-est">
                                            <img
                                                src={
                                                    process.env
                                                        .REACT_APP_BASE_URL_ROOT +
                                                    "logo2.svg"
                                                }
                                                alt="Agrochip"
                                            />
                                        </div>
                                    </SideWrapper>
                                </div>
                            </>
                        )}
                        {dashboard === "mapa" && fazenda.id_fazenda !== "" && (
                            <Legenda
                                key={refreshLegenda}
                                setRefresher={setRefreshLegenda}
                            />
                        )}

                        {dashboard === "dashboard" ? (
                            <div
                                style={{
                                    fontSize: "50px",
                                    paddingLeft: "40px",
                                    paddingTop: "40px",
                                    width: "100vw",
                                    height: "100vh",
                                }}
                            >
                                <div
                                    ref={refScroll}
                                    onScroll={(e: any) => {
                                        handleScroll(e);
                                    }}
                                    style={{
                                        backgroundColor: theme
                                            ? "#303030"
                                            : "#F5F5F5",
                                        height: "100%",
                                        overflow: "auto",
                                    }}
                                >
                                    <Relatorios
                                        scroll={scroll}
                                        referencia={refScroll}
                                    />
                                </div>
                            </div>
                        ) : (
                            <>
                                {fazenda.id_fazenda !== "" ? (
                                    <Map key={mapState} />
                                ) : (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100vh",
                                            backgroundColor: "#CCC",
                                            background:
                                                "url(" +
                                                process.env
                                                    .REACT_APP_BASE_URL_ROOT +
                                                "fnd.png)",
                                        }}
                                    ></div>
                                )}
                            </>
                        )}
                    </>
                )}
                <Modal
                    title={
                        <div
                            style={{
                                userSelect: "none",
                            }}
                        >
                            {processState.modal?.nome}
                        </div>
                    }
                    visible={processState.modal?.ativo} //
                    destroyOnClose={true}
                    forceRender={true}
                    maskStyle={{
                        backdropFilter: "blur(5px)",
                        userSelect: "none",
                    }}
                    onCancel={() => desativarProcess(processState.modal)}
                    footer={null}
                    width={processState.modal?.w ? processState.modal?.w : 800}
                    bodyStyle={{
                        height: processState.modal?.h
                            ? processState.modal?.h
                            : "400px",
                        overflow: "auto",
                        userSelect: "none",
                        padding:
                            processState.modal?.id === 5 ||
                            processState.modal?.id === 6
                                ? "0"
                                : "",
                    }}
                    centered={true}
                    zIndex={1024}
                >
                    {typeof processState.modal?.exe === "function"
                        ? processState.modal?.exe(
                              modalFiltros,
                              setModalFiltros,
                              whichModal,
                              setWhichModal
                          )
                        : processState.modal?.exe}
                </Modal>
                <Modal
                    visible={auth.expired}
                    footer={null}
                    width={300}
                    bodyStyle={{
                        height: "200px",
                    }}
                    zIndex={2000}
                    centered={true}
                    closable={false}
                    maskClosable={false}
                    maskStyle={{
                        backdropFilter: "blur(5px)",
                        userSelect: "none",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            rowGap: "10px",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <CloseCircleOutlined
                            style={{
                                fontSize: "50px",
                                color: "#F44336",
                            }}
                        />
                        <div>A sua sessão expirou.</div>
                        <Button
                            onClick={() => {
                                logoutReset();
                                loginProcessLogOut();
                            }}
                        >
                            Fazer login novamente
                        </Button>
                    </div>
                </Modal>
                <KeepAlive />
            </>
        );
    }
}

function mapStateToProps(store: IStore) {
    return {
        auth: store.auth,
        operacoes: store.operacoes,
        fazenda: store.fazenda,
        equipamento: store.equipamento,
        safra: store.safra,
        tipoOp: store.tipoOp,
        time: store.time,
        cultura: store.cultura,
        loader: store.loader,
        processState: store.processo,
        menuState: store.menu,
        processListState: store.processos,
        mapState: store.mapa,
        prefs: store.preferencia,
        polylines: store.polylines,
        dashboard: store.dashboard,
        polylineFiltro: store.polylineFiltro,
        relatorio: store.relatorio,
        foco: store.mapaFoco,
        keepAlive: store.keepAlive,
        theme: store.theme,
        tipoParadas: store.tipoParadas,
        operador: store.operador,

        talhao: store.talhao,

        tipoOpParada: store.tipoOpParada,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        {
            ativarProcess,
            desativarProcess,
            loginProcess,
            loginProcessLogOut,
            setRefresh,
            getOperacao,
            getPolyline,
            getEquipamentos,
            setTheme,

            editarPreferencia,
            setEquipamento,
            setCultura,
            setSafra,
            setTipoOp,
            setDashboardToggle,
            setFocus,
            getEquipamentoListFiltro,
            getRelatorio,
            getKeepAlive,
            getMensagem,
            getTipoParadas,
            getAlertaRender,

            logoutReset,

            getFiltroTalhoes,

            getCulturaCliente,
            getTipoOpCliente,
            getSafraCliente,

            getImplementos,

            getAlertaUser,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
