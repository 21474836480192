import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setTime, setFocus } from "../../store/actions";
import { useEffect, useState } from "react";
import { getPolyline } from "../../store/polylineAction";
import { ITime } from "../../reducers/TimeReducer";
import { IFazenda, IFazendaModule } from "../../reducers/FazendaReducer";
import { IEquipamento } from "../../reducers/EquipamentoReducer";
import { IStore } from "../../store";
import { DateRangePicker } from "rsuite";
import "../../styles/rsuite.min.css";
import {
    startOfToday,
    startOfDay,
    endOfDay,
    addDays,
    subDays,
    subMonths,
} from "date-fns";
import { ITheme } from "../../reducers/ThemeReducer";
import { setTipoOp, setTipoOpParada } from "../../store/tipoOpActions";
import { ITipoOp } from "../../reducers/TipoOpReducer";

const { afterToday } = DateRangePicker;

export interface ITempoControllerProps {
    time: ITime;
    setTime: Function;
    faz: IFazenda;
    equip: IEquipamento;
    getPolyline: Function;
    //afterToday: any;
    tema: ITheme;

    setTipoOp: Function;
    setFocus: Function;

    tipoOp: ITipoOp;

    tipoOpParada: any;
    setTipoOpParada: Function;
}
function TempoController(props: ITempoControllerProps) {
    const {
        time,
        setTime,
        faz,
        equip,
        getPolyline,
        setFocus,
        setTipoOp,
        tipoOp,

        setTipoOpParada,
        tipoOpParada,
    } = props;

    const [dataInicial, setDataInicial] = useState<any>(startOfToday());
    const [dataFinal, setDataFinal] = useState<any>(new Date());

    const [screenValida, setScreenValida] = useState(window.screen.width < 920);

    function handleTime(v: any) {
        var tzoffset = new Date().getTimezoneOffset() * 60000;

        let dI = new Date(v[0] - tzoffset).toISOString().split(".")[0];
        let dF = new Date(v[1] - tzoffset).toISOString().split(".")[0];
        let dA = new Date(new Date().getTime() - tzoffset)
            .toISOString()
            .split(".")[0];

        if (dF.split("T")[1] === "00:00:00") {
            if (dA !== dF) {
                setTime({
                    i: dI,
                    f: dF.split("T")[0] + "T23:59:59",
                });
                setDataInicial(
                    new Date(dI.split("T")[0] + " " + dI.split("T")[1])
                );
                setDataFinal(new Date(dF.split("T")[0] + " 23:59:59"));
            } else {
                setTime({
                    i: dI,
                    f: dF.split("T")[0] + "T" + dA.split("T")[1],
                });
                setDataInicial(
                    new Date(dI.split("T")[0] + " " + dI.split("T")[1])
                );
                setDataFinal(
                    new Date(dF.split("T")[0] + " " + dA.split("T")[1])
                );
            }
        } else {
            if (dI === dF) {
                setTime({
                    i: dI.split("T")[0] + "T00:00:00",
                    f: dF.split("T")[0] + "T23:59:59",
                });
                setDataInicial(new Date(dI.split("T")[0] + " 00:00:00"));
                setDataFinal(new Date(dF.split("T")[0] + " 23:59:59"));
            } else {
                setTime({
                    i: dI,
                    f: dF,
                });
                setDataInicial(
                    new Date(dI.split("T")[0] + " " + dI.split("T")[1])
                );
                setDataFinal(
                    new Date(dF.split("T")[0] + " " + dF.split("T")[1])
                );
            }
        }

        if (faz !== null) {
            setFocus({
                view: equip.id_equipamento !== "" ? 5 : 7,
                subView: null,
            });
            setTipoOp(Object.keys(tipoOp).length > 0 ? tipoOp : {});
            setTipoOpParada(
                Object.keys(tipoOpParada).length > 0 ? tipoOpParada : {}
            );
        }

        // if (faz !== null) {
        //
        //     getPolyline({
        //         faz: faz.id_fazenda,
        //         equip: equip.id_equipamento,
        //         dataIni: v[0].toISOString().split("T")[0],
        //         dataFin: v[1].toISOString().split("T")[0],
        //     });
        // }
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.screen.width < 920) {
                setScreenValida(true);
            } else setScreenValida(false);
        });
    }, []);

    return (
        <>
            {screenValida ? (
                <div
                    style={{
                        paddingTop: "5px",
                        paddingLeft: "2px",
                    }}
                >
                    <DateRangePicker
                        style={{
                            width: "200px",
                        }}
                        className={
                            props.tema
                                ? "one-dark-calendario"
                                : "one-calendario"
                        }
                        showOneCalendar
                        value={[dataInicial, dataFinal]}
                        disabledDate={afterToday?.()}
                        format="dd/MM/yy"
                        appearance="default"
                        cleanable={false}
                        size="sm"
                        onBlurCapture={() => {}}
                        onChange={handleTime}
                        defaultValue={[startOfToday(), new Date()]}
                        ranges={[
                            {
                                label: "Hoje",
                                value: [startOfToday(), new Date()],
                            },
                            {
                                label: "Ontem",
                                value: [
                                    startOfDay(addDays(new Date(), -1)),
                                    endOfDay(addDays(new Date(), -1)),
                                ],
                            },
                            {
                                label: "Últimos 7 dias",
                                value: [
                                    startOfDay(subDays(new Date(), 6)),
                                    new Date(),
                                ],
                            },
                            {
                                label: "Últimos 30 dias",
                                value: [
                                    startOfDay(subMonths(new Date(), 1)),
                                    new Date(),
                                ],
                            },
                        ]}
                        onOk={handleTime}
                    />
                </div>
            ) : (
                <div
                    style={{
                        paddingTop: "4px",
                        paddingLeft: "5px",
                        zIndex: "2000",
                    }}
                    className="tour-tempo"
                >
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            alignItems: "center",
                            minHeight: "32px",
                        }}
                    >
                        <DateRangePicker
                            className={props.tema ? "dark-calendario" : ""}
                            // renderValue={(v: any, f: any) => {
                            //     let dataI = v[0].toLocaleDateString("pt-BR", {
                            //         day: "2-digit",
                            //         month: "2-digit",
                            //         year: "2-digit",
                            //     });
                            //     let horaI = v[0].toLocaleTimeString("pt-BR");
                            //     let dataF = v[1].toLocaleDateString("pt-BR", {
                            //         day: "2-digit",
                            //         month: "2-digit",
                            //         year: "2-digit",
                            //     });
                            //     let horaF;
                            //     if (v[1].toLocaleTimeString("pt-BR") === "00:00:00") {
                            //         let tzoffset = new Date().getTimezoneOffset() * 60000;
                            //         if (
                            //             v[1].toISOString().split("T")[0] !==
                            //             new Date().toISOString().split("T")[0]
                            //         ) {
                            //             horaF = "23:59:59";
                            //         } else {
                            //             let hF = new Date(new Date().getTime() - tzoffset)
                            //                 .toISOString()
                            //                 .split(".")[0]
                            //                 .split("T")[1];
                            //             horaF = hF;
                            //         }
                            //     } else {
                            //         horaF = v[1].toLocaleTimeString("pt-BR");
                            //     }
                            //     return (
                            //         <div style={{ display: "flex" }}>
                            //             <div
                            //                 style={{
                            //                     width: "50%",
                            //                     display: "flex",
                            //                     justifyContent: "space-between",
                            //                     paddingRight: "5px",
                            //                 }}
                            //             >
                            //                 <div>{dataI}</div>
                            //                 <div style={{ paddingLeft: "5px" }}>
                            //                     {horaI}
                            //                 </div>
                            //             </div>
                            //             <div
                            //                 style={{
                            //                     width: "50%",
                            //                     display: "flex",
                            //                     justifyContent: "space-between",
                            //                     paddingLeft: "5px",
                            //                 }}
                            //             >
                            //                 <div>{dataF}</div>
                            //                 <div style={{ paddingLeft: "5px" }}>
                            //                     {horaF}
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     );
                            // }}
                            value={[dataInicial, dataFinal]}
                            disabledDate={afterToday?.()}
                            format="dd/MM/yy HH:mm"
                            appearance="default"
                            cleanable={false}
                            size="sm"
                            onChange={handleTime}
                            defaultValue={[startOfToday(), new Date()]}
                            ranges={[
                                {
                                    label: "Hoje",
                                    value: [startOfToday(), new Date()],
                                },
                                {
                                    label: "Ontem",
                                    value: [
                                        startOfDay(addDays(new Date(), -1)),
                                        endOfDay(addDays(new Date(), -1)),
                                    ],
                                },
                                {
                                    label: "Última Semana",
                                    value: [
                                        startOfDay(subDays(new Date(), 6)),
                                        new Date(),
                                    ],
                                },
                                {
                                    label: "Último mês",
                                    value: [
                                        startOfDay(subMonths(new Date(), 1)),
                                        new Date(),
                                    ],
                                },
                            ]}
                            // container={() =>  <div> opa </div>}
                        />
                    </div>

                    {/*<DateTimeRangePicker value={[new Date(), new Date()]} />
             <Calendar
                // formatShortWeekday={(l: any , d: any) => {
                //     switch (d.getDay()) {
                //         case 0:
                //             return "Dom";
                //         case 1:
                //             return "Seg";
                //         case 2:
                //             return "Ter";
                //         case 3:
                //             return "Qua";
                //         case 4:
                //             return "Qui";
                //         case 5:
                //             return "Sex";
                //         case 6:
                //             return "Sab";
                //         default:
                //             return null;
                //     }
                // }}
                maxDate={new Date()}
                selectRange={true}
                onChange={handleTime}
            /> */}
                </div>
            )}{" "}
        </>
    );
}

function mapStateToProps(store: IStore) {
    return {
        time: store.time,
        faz: store.fazenda,
        equip: store.equipamento,
        tipoOp: store.tipoOp,

        tipoOpParada: store.tipoOpParada,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators(
        { setTime, getPolyline, setTipoOp, setFocus, setTipoOpParada },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(TempoController);
