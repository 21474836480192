import {
    SET_FAZENDA,
    SET_LIST_FAZENDA,
    SET_TALHAO,
} from "../store/actionTypes";

export interface IFazendaModule {
    id_fazenda?: string;
    nome?: string;
    shape: string;
    coordenadas: string;
    estilo: string;
    icone?: string;
    fields: string;
    campo: string;
    fuso?: string;
    cidade?: string;
    cod_erp?: string;
}

export type ITalhao = any;

export type IFazenda = IFazendaModule;

export type IFazendaList = IFazendaModule[];

export interface IFazendaAction {
    type: any;
    payload: IFazenda;
}
export interface ITalhaoAction {
    type: any;
    payload: ITalhao;
}
export interface IFazendaListAction {
    type: any;
    payload: IFazendaList;
}

const Talhao: ITalhao = Object;

const Fazenda: IFazenda = {
    id_fazenda: "",
    nome: "",
    shape: "",
    coordenadas: "",
    estilo: "",
    icone: "",
    fields: "",
    campo: "" as string,
    fuso: "",
    cidade: "",
    cod_erp: "",
};

const FazendaList: IFazendaList = [];

export const fazendaReducer = (state = Fazenda, action: IFazendaAction) => {
    switch (action.type) {
        case SET_FAZENDA:
            return action.payload;
        default:
            return state;
    }
};

export const fazendaListReducer = (
    state = FazendaList,
    action: IFazendaListAction
) => {
    switch (action.type) {
        case SET_LIST_FAZENDA:
            return action.payload;
        default:
            return state;
    }
};

export const talhaoReducer = (state = Talhao, action: ITalhaoAction) => {
    switch (action.type) {
        case SET_TALHAO:
            return action.payload;
        default:
            return state;
    }
};

export const fusoList = [
    {
        displayName: "GMT - 02:00 Horário Padrão de Fernando de Noronha",
        value: "GMT-2",
    },
    { displayName: "GMT - 03:00 Horário Padrão de Brasília", value: "GMT-3" },
    { displayName: "GMT - 04:00 Horário Padrão do Amazonas", value: "GMT-4" },
    { displayName: "GMT - 05:00 Horário Padrão do Acre", value: "GMT-5" },
];
