import { Modal, Select, Form, Tooltip, Popover, Progress } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import ReactSpeedometer from "react-d3-speedometer";
import { segParaHoras } from "../../../config/helpers";
import { Spinner } from "../../../styles/Main";
import { v4 as uuidv4 } from "uuid";
import { IStore } from "../../../store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MultiToggle from "react-multi-toggle";

const { Option } = Select;

interface IEstiloProps {
    quartos?: any;
    tema?: boolean;
}

const EstiloBoxGeral = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
`;
const EstiloBox = styled.div<IEstiloProps>`
    display: flex;
    flex-direction: column;
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    min-height: 200px;
    width: calc(
        ${props => {
                switch (props.quartos) {
                    case 1:
                        return "25%";
                    case 2:
                        return "50%";
                    case 3:
                        return "75%";
                    case 4:
                        return "100%";
                    default:
                        return "25%";
                }
            }} - 5px
    );
    justify-content: space-between;
    padding: 10px;
    background-color: ${props => (props.tema ? "#424242" : "#e8e8e8")};
`;
const EstiloBoxTitulo = styled.div`
    font-size: 16px;
    margin-left: 10px;
    font-weight: bold;
`;
const EstiloBoxConteudo = styled.div`
    align-self: center;
    font-size: 22px;
    font-weight: bold;
`;
const EstiloBoxRodape = styled.div`
    display: flex;
    justify-content: space-between;
`;

function ModalEquipa(props: any) {
    const { tiposOp } = props;
    // const [graficoRpmVazao(), setgraficoRpmVazao()] = useState(Object);
    const [form] = Form.useForm();
    const [graf, setGraf] = useState("situacao");
    const [graficoParada, setGraficoParada] = useState(Object);
    const [grafSituacao, setGrafSituacao] = useState(Object);

    const [validaToggle, setValidaToggle] = useState(false);
    const [series, setSeries] = useState(Array);
    //const [tipos, setTipos] = useState(Array);

    const [optionsSelected, setOptionsSelected] = useState<any>([]);

    const [sumGraf, setSumGraf] = useState(0);

    const handleChange = (value: any) => {
        setOptionsSelected(value);
    };

    var tzoffset = new Date().getTimezoneOffset() * 60000;

    // useEffect(() => {
    //     setTipos([
    //         "velocidade",
    //         "RPM",
    //         "vazao_comb",
    //         "pressao_oleo",
    //         "temperatura",
    //     ]);
    // }, []);

    var tipos = [
        { id: "vel", label: "Velocidade" },
        { id: "RPM", label: "RPM" },
        { id: "vazao", label: "Vazão de Combustível" },
        { id: "pressao_oleo", label: "Pressão do Óleo do Motor" },
        { id: "temp", label: "Temperatura da Água do Motor" },
    ];

    const colorList = [
        "#bcd631",
        "#093061",
        "#75c043",
        "#008ed1",
        "#006830",
        "#205fad",
        "#ABFF73",
        "#91C1FB",
        "#45E329",
        "#2212E0",
        "#00B554",
        "#0C68E8",
        "#E5FF54",
        "#261C94",
        "#36B571",
        "#2266BA",
        "#69BF30",
    ];

    const graficoRpmVazao = useCallback(() => {
        let rpmGraf: any = [];
        let vazaoGraf: any = [];
        let labelsGraf: any = [];
        let velGraf: any = [];
        let pressaoGraf: any = [];
        let tempGraf: any = [];

        let graf: any;

        if (
            props.dadosOpe !== undefined &&
            Object.keys(props.dadosOpe.operacoes).length > 0
        ) {
            let filtroGraf = props.dadosOpe.operacoes.filter((f: any) => {
                return f.timestamp.split("T")[0] === props.diaSelec;
            });

            // turnoSelec.forEach((t: any) => {
            //     let horas = filtroGraf.filter((h: any) => {
            //         let manhaT = new Date(diaSelec + "06:00:00");
            //         let tardeT = new Date(diaSelec + "12:00:00");
            //         let noiteT = new Date(diaSelec + "18:00:00");
            //         let madrugadaT = new Date(diaSelec + "00:00:00");
            //     });
            // });

            // ;

            filtroGraf.forEach((d: any) => {
                rpmGraf.push([
                    new Date(d.timestamp).getTime() - tzoffset,
                    (d.rpm / 100).toFixed(2),
                ]);
                labelsGraf.push(d.timestamp);
                velGraf.push([
                    new Date(d.timestamp).getTime() - tzoffset,
                    d.velocidade,
                ]);
                if (props.frameMenor === false) {
                    vazaoGraf.push([
                        new Date(d.timestamp).getTime() - tzoffset,
                        d.vazaoComb,
                    ]);
                    pressaoGraf.push([
                        new Date(d.timestamp).getTime() - tzoffset,
                        d.pressaoOleo.toFixed(2),
                    ]);
                    tempGraf.push([
                        new Date(d.timestamp).getTime() - tzoffset,
                        d.temAgua,
                    ]);
                }
            });
            graf = props.frameMenor
                ? {
                      labels: labelsGraf,
                      RPM: rpmGraf,
                      vazao: vazaoGraf,
                      vel: velGraf,
                      temp: tempGraf,
                      pressao_oleo: pressaoGraf,
                      vMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.velocidade === props.velMax
                              )
                          ].timestamp
                      ),
                      vMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.velocidade === props.velMin
                              )
                          ].timestamp
                      ),
                      rMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.rpm === props.rpmMax
                              )
                          ].timestamp
                      ),
                      rMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.rpm === props.rpmMin
                              )
                          ].timestamp
                      ),
                  }
                : {
                      labels: labelsGraf,
                      RPM: rpmGraf,
                      vazao: vazaoGraf,
                      vel: velGraf,
                      temp: tempGraf,
                      pressao_oleo: pressaoGraf,
                      vMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.velocidade === props.velMax
                              )
                          ].timestamp
                      ),
                      vMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.velocidade === props.velMin
                              )
                          ].timestamp
                      ),
                      rMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.rpm === props.rpmMax
                              )
                          ].timestamp
                      ),
                      rMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (v: any) => v.rpm === props.rpmMin
                              )
                          ].timestamp
                      ),
                      pMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (p: any) => p.pressaoOleo === props.pressaoMax
                              )
                          ].timestamp
                      ),
                      pMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (p: any) => p.pressaoOleo === props.pressaoMin
                              )
                          ].timestamp
                      ),
                      tMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.temAgua === props.tempMax
                              )
                          ].timestamp
                      ),
                      tMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.temAgua === props.tempMin
                              )
                          ].timestamp
                      ),
                      cMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.cargaMotor === props.cargaMax
                              )
                          ].timestamp
                      ),
                      cMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.cargaMotor === props.cargaMin
                              )
                          ].timestamp
                      ),
                      tqMaxTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.torqueMotor === props.torqMax
                              )
                          ].timestamp
                      ),
                      tqMinTime: new Date(
                          props.dadosOpe.operacoes[
                              props.dadosOpe.operacoes.findIndex(
                                  (t: any) => t.torqueMotor === props.torqMin
                              )
                          ].timestamp
                      ),
                  };
        }

        return graf;
    }, [props.dadosOpe, props.diaSelec, props]);

    useEffect(() => {
        if (optionsSelected.length > 0) {
            let arr: any[] = [];
            optionsSelected.forEach((o: any) => {
                arr.push({
                    name: tipos[tipos.findIndex((t: any) => t.id === o)].label,
                    data: graficoRpmVazao()[o],
                });
            });

            setSeries(arr);
        }
    }, [optionsSelected, graficoRpmVazao]);
    useEffect(() => {
        if (props.openModal === true) {
            form.setFieldsValue({ tipo01: ["vel", "vazao", "RPM"] });
            setOptionsSelected(["vel", "vazao", "RPM"]);
        }
    }, [props.openModal]);

    function handleGraf(value: any) {
        if (value === "situacao") {
            setGraf(value);
        }
        if (
            props.dadosRel.tipoParada.filter((t: any) => t.tempo_parado >= 60)
                .length > 0 &&
            value === "parada"
        ) {
            setGraf(value);
        }
    }

    useEffect(() => {
        let habilita = props.dadosRel.tipoParada.filter(
            (t: any) => t.tempo_parado >= 60
        );
        if (habilita.length > 0) {
            setValidaToggle(true);
        } else {
            setValidaToggle(false);
        }
    }, [props.dadosRel]);

    useEffect(() => {
        setGraf("situacao");
    }, [props.openModal]);

    useEffect(() => {
        let labelsGraf: any = [];
        let dadosGraf: any = [];
        let paradoGraf: any = 0;
        let opeGraf: any = 0;
        let deslocGraf: any = 0;

        if (props.dadosRel !== undefined) {
            props.dadosRel.tipoParada.forEach((p: any) => {
                if (p.tempo_parado < 60) {
                    return;
                }
                labelsGraf.push(p.tipo_parada);
                dadosGraf.push(p.tempo_parado);
            });
            setGraficoParada({
                labels: labelsGraf,
                dados: dadosGraf,
            });
            if (
                props.dadosRel.tempoParado.tempo_total -
                    props.dadosRel.tempoParado.tempo_parado -
                    props.dadosRel.tempoParado.tempo_total_deslocamento <
                60
            ) {
                opeGraf = 0;
            } else {
                opeGraf =
                    props.dadosRel.tempoParado.tempo_total -
                    props.dadosRel.tempoParado.tempo_parado -
                    props.dadosRel.tempoParado.tempo_total_deslocamento;
            }
            paradoGraf =
                props.dadosRel.tempoParado.tempo_parado >= 60 &&
                props.dadosRel.tempoParado.tempo_parado;
            deslocGraf =
                props.dadosRel.tempoParado.tempo_total_deslocamento >= 60 &&
                props.dadosRel.tempoParado.tempo_total_deslocamento;
            setGrafSituacao({
                paradoGraf,
                opeGraf,
                deslocGraf,
            });
        }
    }, [props.dadosRel]);

    useEffect(() => {
        if (graficoParada.dados) {
            setSumGraf(
                graficoParada.dados.reduce((a: any, b: any) => a + b, 0)
            );
        }
    }, [graficoParada]);

    if (Object.keys(graficoRpmVazao()).length > 0) {
        return (
            <Modal
                destroyOnClose={true}
                width="100%"
                visible={props.openModal}
                title={
                    props.equipamento.numero_patrimonio +
                    " - " +
                    props.equipamento.descricao
                }
                maskStyle={{ backdropFilter: "blur(3px)" }}
                bodyStyle={{
                    height: "calc(100vh - 100px)",
                    overflow: "auto",
                }}
                footer={null}
                centered={true}
                onCancel={() => props.setOpenModal(false)}
            >
                <EstiloBoxGeral>
                    <EstiloBox tema={props.tema}>
                        <EstiloBoxTitulo>
                            <i className="fas fa-gas-pump"></i> Consumo
                        </EstiloBoxTitulo>
                        <EstiloBoxConteudo>
                            {props.dadosRel.tempoParado.consumo_total.toFixed(
                                2
                            )}{" "}
                            L
                        </EstiloBoxConteudo>

                        <EstiloBoxRodape>
                            <div>
                                Período {props.horas}h{props.minutos}min
                            </div>
                            <div>Média {props.mediaConsumo} L/hora</div>
                        </EstiloBoxRodape>
                    </EstiloBox>
                    <EstiloBox tema={props.tema}>
                        <EstiloBoxTitulo>
                            <i className="fas fa-tachometer-alt"></i> Velocidade
                        </EstiloBoxTitulo>
                        <EstiloBoxConteudo>
                            <ReactSpeedometer
                                minValue={0}
                                maxValue={60}
                                ringWidth={50}
                                value={props.velMedia}
                                needleColor={props.tema ? "#fff" : "black"}
                                customSegmentStops={[0, 10, 20, 30, 40, 50, 60]}
                                needleTransitionDuration={100}
                                width={170}
                                height={120}
                                textColor={props.tema ? "#fff" : "black"}
                                labelFontSize="10px"
                                valueTextFontSize="14px"
                                startColor="#00A300"
                                endColor="red"
                                currentValueText="${value}Km/h"
                            />
                        </EstiloBoxConteudo>
                        <EstiloBoxRodape>
                            <div>
                                <Tooltip
                                    title={
                                        <>
                                            <i
                                                className="far fa-clock"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            ></i>
                                            {graficoRpmVazao().vMaxTime.toLocaleDateString(
                                                "pt-BR",
                                                {
                                                    weekday: "long",
                                                    day: "2-digit",
                                                    month: "long",
                                                    year: "numeric",
                                                    hour12: true,
                                                }
                                            )}
                                            {" - "}
                                            {graficoRpmVazao().vMaxTime.toLocaleTimeString(
                                                "pt-BR"
                                            )}
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    Vel. máxima {props.velMax} km/h
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    title={
                                        <>
                                            <i
                                                className="far fa-clock"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            ></i>
                                            {graficoRpmVazao().vMinTime.toLocaleDateString(
                                                "pt-BR",
                                                {
                                                    weekday: "long",
                                                    day: "2-digit",
                                                    month: "long",
                                                    year: "numeric",
                                                    hour12: true,
                                                }
                                            )}
                                            {" - "}
                                            {graficoRpmVazao().vMinTime.toLocaleTimeString(
                                                "pt-BR"
                                            )}
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    Vel. mínima {props.velMin} km/h
                                </Tooltip>
                            </div>
                        </EstiloBoxRodape>
                    </EstiloBox>
                    <EstiloBox tema={props.tema}>
                        <EstiloBoxTitulo>
                            <i className="fas fa-sync"></i> Rotação
                        </EstiloBoxTitulo>
                        <EstiloBoxConteudo>
                            <ReactSpeedometer
                                minValue={0}
                                maxValue={10}
                                ringWidth={50}
                                value={
                                    props.rpmMedia !== undefined &&
                                    props.rpmMedia > 0
                                        ? props.rpmMedia / 1000
                                        : 0
                                }
                                needleColor={props.tema ? "#fff" : "black"}
                                customSegmentStops={[
                                    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
                                ]}
                                needleTransitionDuration={600}
                                width={170}
                                height={120}
                                textColor={props.tema ? "#fff" : "black"}
                                labelFontSize="10px"
                                valueTextFontSize="14px"
                                startColor="#FFFF00"
                                endColor="red"
                                currentValueText="RPM x1000"
                            />
                            <div
                                style={{
                                    marginTop: "-15px",
                                    display: "flex",
                                    justifyContent: "center",
                                    fontSize: "16px",
                                }}
                            >
                                {props.rpmMedia} RPM
                            </div>
                        </EstiloBoxConteudo>
                        <EstiloBoxRodape>
                            <div>
                                <Tooltip
                                    title={
                                        <>
                                            <i
                                                className="far fa-clock"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            ></i>
                                            {graficoRpmVazao().rMaxTime.toLocaleDateString(
                                                "pt-BR",
                                                {
                                                    weekday: "long",
                                                    day: "2-digit",
                                                    month: "long",
                                                    year: "numeric",
                                                    hour12: true,
                                                }
                                            )}
                                            {" - "}
                                            {graficoRpmVazao().rMaxTime.toLocaleTimeString(
                                                "pt-BR"
                                            )}
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    RPM máxima {props.rpmMax}
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip
                                    title={
                                        <>
                                            <i
                                                className="far fa-clock"
                                                style={{
                                                    marginRight: "10px",
                                                }}
                                            ></i>
                                            {graficoRpmVazao().rMinTime.toLocaleDateString(
                                                "pt-BR",
                                                {
                                                    weekday: "long",
                                                    day: "2-digit",
                                                    month: "long",
                                                    year: "numeric",
                                                    hour12: true,
                                                }
                                            )}
                                            {" - "}
                                            {graficoRpmVazao().rMinTime.toLocaleTimeString(
                                                "pt-BR"
                                            )}
                                        </>
                                    }
                                    placement="bottom"
                                >
                                    RPM mínima {props.rpmMin}
                                </Tooltip>
                            </div>
                        </EstiloBoxRodape>
                    </EstiloBox>
                    <EstiloBox tema={props.tema}>
                        <EstiloBoxTitulo>
                            <i className="fas fa-exclamation-triangle"></i>{" "}
                            Alertas
                        </EstiloBoxTitulo>
                        <EstiloBoxConteudo>
                            {props.numAlertas} alertas
                        </EstiloBoxConteudo>
                        <EstiloBoxRodape></EstiloBoxRodape>
                    </EstiloBox>
                    <EstiloBox quartos={3} tema={props.tema}>
                        <div
                            style={{
                                display: "flex",
                            }}
                        >
                            <div
                                style={{
                                    display: "flex",
                                    columnGap: "5px",
                                }}
                            >
                                {" "}
                                <Form
                                    form={form}
                                    initialValues={{ data: props.diaSelec }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-start",
                                            columnGap: "5px",
                                            alignItems: "stretch",
                                            flex: 1,
                                        }}
                                    >
                                        <Form.Item name="data">
                                            <Select
                                                // size="small"
                                                style={{
                                                    minWidth: "120px",
                                                    width: "100%",
                                                    backgroundColor: props.tema
                                                        ? "#757575"
                                                        : "",
                                                }}
                                                onSelect={(value: any) => {
                                                    props.setDiaSelec(value);
                                                }}
                                            >
                                                {props.dias.map((d: any) => {
                                                    return (
                                                        <Option
                                                            value={d}
                                                            key={uuidv4()}
                                                        >
                                                            {d}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </Form.Item>{" "}
                                        <Form.Item name="tipo01">
                                            <Select
                                                mode="multiple"
                                                style={{ minWidth: "150px" }}
                                                placeholder="Selecione"
                                                onChange={handleChange}
                                            >
                                                {tipos.map((t: any) => (
                                                    <Option
                                                        disabled={
                                                            optionsSelected.length >
                                                            2
                                                                ? optionsSelected.includes(
                                                                      t.id
                                                                  )
                                                                    ? false
                                                                    : true
                                                                : false
                                                        }
                                                        key={t.id}
                                                    >
                                                        {t.label}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    flexGrow: "2",
                                    marginRight: "10px",
                                    fontSize: "20px",
                                    justifyContent: "flex-end",
                                    marginTop: "5px",
                                }}
                            >
                                <Popover
                                    title="Gráfico dinâmico"
                                    trigger="click"
                                    color={props.tema && "#424242"}
                                    content={
                                        <div>
                                            Você escolhe um dos dias em que o{" "}
                                            <br></br>equipamento trabalhou.
                                            <br></br> E seleciona quais
                                            indicadores deseja <br></br>comparar
                                            no gráfico.
                                        </div>
                                    }
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            </div>
                        </div>

                        {Object.keys(graficoRpmVazao()).length > 0 && (
                            <>
                                <Chart
                                    type="line"
                                    series={series}
                                    options={{
                                        theme: {
                                            mode: props.tema ? "dark" : "light",
                                        },
                                        colors: [
                                            "#bcd631",
                                            "#205fad",
                                            "#75c043",
                                        ],
                                        chart: {
                                            id: "grafRpm",
                                            toolbar: {
                                                autoSelected: "pan",
                                                show: false,
                                            },
                                        },

                                        xaxis: {
                                            type: "datetime",
                                        },

                                        markers: {
                                            size: 0,
                                        },
                                        stroke: {
                                            width: 3,
                                        },

                                        tooltip: {
                                            fillSeriesColor: false,
                                            shared: true,
                                            theme: props.tema
                                                ? "dark"
                                                : "light",
                                            y: [
                                                {
                                                    formatter: (
                                                        v: any,
                                                        { seriesIndex, w }: any
                                                    ) => {
                                                        if (
                                                            w.globals
                                                                .seriesNames[0] ===
                                                            "Velocidade"
                                                        ) {
                                                            return v + "Km/h";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[0] ===
                                                            "RPM"
                                                        ) {
                                                            return (
                                                                v * 100
                                                            ).toFixed(2);
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[0] ===
                                                            "Vazão de Combustível"
                                                        ) {
                                                            return v + "L";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[0] ===
                                                            "Pressão do Óleo do Motor"
                                                        ) {
                                                            return v + " psi";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[0] ===
                                                            "Temperatura da Água do Motor"
                                                        ) {
                                                            return v + "°C";
                                                        }
                                                        return v;
                                                    },
                                                },
                                                {
                                                    formatter: (
                                                        v: any,
                                                        { w }: any
                                                    ) => {
                                                        if (
                                                            w.globals
                                                                .seriesNames[1] ===
                                                            "Velocidade"
                                                        ) {
                                                            return v + "Km/h";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[1] ===
                                                            "RPM"
                                                        ) {
                                                            return (
                                                                v * 100
                                                            ).toFixed(2);
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[1] ===
                                                            "Vazão de Combustível"
                                                        ) {
                                                            return v + "L";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[1] ===
                                                            "Pressão do Óleo do Motor"
                                                        ) {
                                                            return v + "psi";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[1] ===
                                                            "Temperatura da Água do Motor"
                                                        ) {
                                                            return v + "°C";
                                                        }
                                                        return v;
                                                    },
                                                },
                                                {
                                                    formatter: (
                                                        v: any,
                                                        { w }: any
                                                    ) => {
                                                        if (
                                                            w.globals
                                                                .seriesNames[2] ===
                                                            "Velocidade"
                                                        ) {
                                                            return v + "Km/h";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[2] ===
                                                            "RPM"
                                                        ) {
                                                            return (
                                                                v * 100
                                                            ).toFixed(2);
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[2] ===
                                                            "Vazão de Combustível"
                                                        ) {
                                                            return v + "L";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[2] ===
                                                            "Pressão do Óleo do Motor"
                                                        ) {
                                                            return v + " psi";
                                                        }
                                                        if (
                                                            w.globals
                                                                .seriesNames[2] ===
                                                            "Temperatura da Água do Motor"
                                                        ) {
                                                            return v + " °C";
                                                        }
                                                        return v;
                                                    },
                                                },
                                            ],
                                        },
                                    }}
                                    height={220}
                                />
                                <Chart
                                    series={series}
                                    type="area"
                                    options={{
                                        theme: {
                                            mode: props.tema ? "dark" : "light",
                                        },
                                        colors: [
                                            "#bcd631",
                                            "#205fad",
                                            "#75c043",
                                        ],
                                        chart: {
                                            id: "chart1",
                                            brush: {
                                                target: "grafRpm",
                                                enabled: true,
                                            },
                                            selection: {
                                                enabled: true,
                                                xaxis: {
                                                    // min:
                                                    //     new Date(
                                                    //         props.diaSelec +
                                                    //             "T09:00:00"
                                                    //     ).getTime() - tzoffset,
                                                    // max:
                                                    //     new Date(
                                                    //         props.diaSelec +
                                                    //             "T11:59:59"
                                                    //     ).getTime() - tzoffset,
                                                },
                                                fill: {
                                                    color: "#fff",
                                                    opacity: 0.4,
                                                },
                                            },
                                        },
                                        dataLabels: {
                                            enabled: false,
                                        },
                                        legend: {
                                            show: false,
                                        },
                                        xaxis: {
                                            type: "datetime",
                                            tooltip: {
                                                enabled: false,
                                            },
                                        },
                                        yaxis: {
                                            tickAmount: 1,
                                            labels: { show: false },
                                        },
                                        fill: {
                                            opacity: 1,
                                            colors: ["#008ed1"],
                                            type: "gradient",
                                        },
                                    }}
                                    height={130}
                                    style={{ marginTop: "-15px" }}
                                />
                            </>
                        )}
                    </EstiloBox>
                    <EstiloBox tema={props.tema}>
                        <MultiToggle
                            className={
                                props.tema
                                    ? validaToggle === true
                                        ? "graf-toggle"
                                        : "graf-toggle-disabled"
                                    : validaToggle === true
                                    ? "graf-toggle-light"
                                    : "graf-toggle-light-disabled"
                            }
                            options={[
                                {
                                    displayName: "Tempo por Situação",
                                    value: "situacao",
                                    optionClass: "situacao",
                                },
                                {
                                    displayName: "Tempo por Parada",
                                    value: "parada",
                                    optionClass: "parada",
                                },
                            ]}
                            selectedOption={graf}
                            onSelectOption={handleGraf}
                        />
                        {graf === "situacao" ? (
                            <>
                                {/* <EstiloBoxTitulo>
                                    Tempo por situação
                                </EstiloBoxTitulo> */}
                                <Chart
                                    series={[
                                        grafSituacao.paradoGraf,
                                        grafSituacao.opeGraf,
                                        grafSituacao.deslocGraf,
                                    ]}
                                    type="donut"
                                    options={{
                                        theme: {
                                            mode: props.tema ? "dark" : "light",
                                        },
                                        colors: [
                                            "#bcd631",
                                            "#205fad",
                                            "#75c043",
                                        ],
                                        legend: {
                                            position: "bottom",
                                        },
                                        stroke: {
                                            width: 0,
                                        },
                                        labels: [
                                            "Tempo Parado",
                                            "Tempo em Operação",
                                            "Tempo em Deslocamento",
                                        ],
                                        tooltip: {
                                            fillSeriesColor: false,
                                            theme: props.tema
                                                ? "dark"
                                                : "light",
                                            enabled: true,
                                            x: {
                                                show: false,
                                            },

                                            y: {
                                                formatter: (
                                                    value: any,
                                                    w: any
                                                ) => {
                                                    return (
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).h +
                                                        "h" +
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).m +
                                                        "min"
                                                    );
                                                },
                                            },
                                        },
                                    }}
                                    width={350}
                                    style={{
                                        alignSelf: "center",
                                        marginTop: "15px",
                                    }}
                                />
                                <EstiloBoxRodape>
                                    Consumindo um total de{" "}
                                    {props.dadosRel.tempoParado.consumo_parado.toFixed(
                                        2
                                    )}
                                    L em tempo parado,{" "}
                                    {props.dadosRel.tempoParado.consumo_total_deslocamento.toFixed(
                                        2
                                    )}
                                    L em deslocamento, e um total de{" "}
                                    {(
                                        props.dadosRel.tempoParado
                                            .consumo_total -
                                        props.dadosRel.tempoParado
                                            .consumo_parado -
                                        props.dadosRel.tempoParado
                                            .consumo_total_deslocamento
                                    ).toFixed(2)}
                                    L em tempo operando
                                </EstiloBoxRodape>
                            </>
                        ) : (
                            <>
                                <div
                                    style={{
                                        height: "300px",
                                        marginLeft: "15px",
                                        overflow: "auto",
                                    }}
                                >
                                    {graficoParada.dados.map((d: any) => {
                                        return (
                                            <>
                                                {" "}
                                                <div
                                                    style={{
                                                        marginBottom: "-5px",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    <span>
                                                        {
                                                            graficoParada
                                                                .labels[
                                                                graficoParada.dados.findIndex(
                                                                    (i: any) =>
                                                                        d === i
                                                                )
                                                            ]
                                                        }{" "}
                                                        -{" "}
                                                        {segParaHoras(
                                                            d,
                                                            "horas"
                                                        ).h +
                                                            "h" +
                                                            segParaHoras(
                                                                d,
                                                                "horas"
                                                            ).m +
                                                            "min"}
                                                    </span>
                                                </div>
                                                <Progress
                                                    size="small"
                                                    percent={
                                                        (d * 100) / sumGraf
                                                    }
                                                    style={{
                                                        width: "300px",
                                                        marginBottom: "5px",
                                                    }}
                                                    trailColor="transparent"
                                                    strokeLinecap="square"
                                                    strokeColor={
                                                        colorList[
                                                            graficoParada.dados.findIndex(
                                                                (i: any) =>
                                                                    d === i
                                                            )
                                                        ]
                                                    }
                                                    showInfo={false}
                                                />
                                            </>
                                        );
                                    })}{" "}
                                </div>
                                <EstiloBoxRodape>
                                    <div>
                                        Totalizando{" "}
                                        {
                                            segParaHoras(
                                                props.dadosRel.tempoParado
                                                    .tempo_parado,
                                                "horas"
                                            ).h
                                        }
                                        h
                                        {
                                            segParaHoras(
                                                props.dadosRel.tempoParado
                                                    .tempo_parado,
                                                "horas"
                                            ).m
                                        }
                                        min com o equipamento parado.
                                    </div>
                                </EstiloBoxRodape>

                                {/* <Chart
                                    type="bar"
                                    series={[
                                        {
                                            data: graficoParada.dados,
                                        },
                                    ]}
                                    options={{
                                        theme: {
                                            mode: props.tema ? "dark" : "light",
                                        },
                                        colors: [
                                            "#bcd631",
                                            "#093061",
                                            "#75c043",
                                            "#008ed1",
                                            "#006830",
                                            "#205fad",
                                            "#ABFF73",
                                            "#91C1FB",
                                            "#45E329",
                                            "#2212E0",
                                            "#00B554",
                                            "#0C68E8",
                                            "#E5FF54",
                                            "#261C94",
                                            "#36B571",
                                            "#2266BA",
                                            "#69BF30",
                                        ],
                                        legend: {
                                            show: false,
                                        },
                                        plotOptions: {
                                            bar: {
                                                borderRadius: 2,
                                                horizontal: true,
                                                barHeight: "90%",
                                                distributed: true,
                                                dataLabels: {
                                                    position: "bottom",
                                                },
                                            },
                                        },
                                        xaxis: {
                                            categories: graficoParada.labels,
                                            labels: {
                                                show: false,
                                            },
                                        },
                                        yaxis: {
                                            labels: {
                                                show: false,
                                            },
                                        },
                                        dataLabels: {
                                            enabled: true,
                                            textAnchor: "start",

                                            style: {
                                                colors: ["#fff"],
                                            },
                                            formatter: function (val, opt) {
                                                return (
                                                    opt.w.globals.labels[
                                                        opt.dataPointIndex
                                                    ] +
                                                    ":  " +
                                                    segParaHoras(val, "horas")
                                                        .h +
                                                    "h" +
                                                    segParaHoras(val, "horas")
                                                        .m +
                                                    "min"
                                                );
                                            },

                                            offsetX: 0,
                                            dropShadow: {
                                                enabled: true,
                                            },
                                        },
                                        labels: graficoParada.labels,

                                        tooltip: {
                                            fillSeriesColor: false,
                                            theme: props.tema
                                                ? "dark"
                                                : "light",
                                            enabled: true,
                                            shared: false,

                                            x: {
                                                show: false,
                                            },
                                            y: {
                                                title: {
                                                    formatter: function () {
                                                        return "";
                                                    },
                                                },

                                                formatter: (
                                                    value: any,
                                                    opt: any
                                                ) => {
                                                    return (
                                                        opt.w.globals.labels[
                                                            opt.dataPointIndex
                                                        ] +
                                                        ":  " +
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).h +
                                                        "h" +
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).m +
                                                        "min"
                                                    );
                                                },
                                            },
                                        },
                                    }}
                                    width={350}
                                    height={350}
                                    style={{
                                        alignSelf: "center",
                                        marginTop: "10px",
                                    }}
                                /> */}
                                {/* <Chart
                                    series={graficoParada.dados}
                                    type="pie"
                                    options={{
                                        theme: {
                                            mode: props.tema ? "dark" : "light",
                                        },
                                        colors: [
                                            "#bcd631",
                                            "#093061",
                                            "#75c043",
                                            "#008ed1",
                                            "#006830",
                                            "#205fad",
                                            "#ABFF73",
                                            "#91C1FB",
                                            "#45E329",
                                            "#2212E0",
                                            "#00B554",
                                            "#0C68E8",
                                            "#E5FF54",
                                            "#261C94",
                                            "#36B571",
                                            "#2266BA",
                                            "#69BF30",
                                        ],
                                        legend: {
                                            position: "bottom",
                                        },
                                        labels: graficoParada.labels,
                                        stroke: {
                                            width: 0,
                                        },
                                        tooltip: {
                                            fillSeriesColor: false,
                                            theme: props.tema
                                                ? "dark"
                                                : "light",
                                            enabled: true,
                                            x: {
                                                show: false,
                                            },

                                            y: {
                                                formatter: (
                                                    value: any,
                                                    w: any
                                                ) => {
                                                    let teste =
                                                        w.globals.seriesPercent[
                                                            w.seriesIndex
                                                        ];
                                                    return (
                                                        teste[0].toFixed(2) +
                                                        "% - " +
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).h +
                                                        "h" +
                                                        segParaHoras(
                                                            value,
                                                            "horas"
                                                        ).m +
                                                        "min"
                                                    );
                                                },
                                            },
                                        },
                                    }}
                                    width={350}
                                    height={320}
                                    style={{
                                        alignSelf: "center",
                                    }}
                                />
                                <EstiloBoxRodape>
                                    <div>
                                        Totalizando{" "}
                                        {
                                            segParaHoras(
                                                props.dadosRel.tempoParado
                                                    .tempo_parado,
                                                "horas"
                                            ).h
                                        }
                                        h
                                        {
                                            segParaHoras(
                                                props.dadosRel.tempoParado
                                                    .tempo_parado,
                                                "horas"
                                            ).m
                                        }
                                        min com o equipamento parado.
                                    </div>
                                </EstiloBoxRodape> */}
                            </>
                        )}
                    </EstiloBox>
                    {props.frameMenor === false && (
                        <>
                            <EstiloBox tema={props.tema}>
                                <EstiloBoxTitulo>
                                    <i className="fas fa-thermometer-full"></i>{" "}
                                    Temperatura do Motor
                                </EstiloBoxTitulo>
                                <EstiloBoxConteudo>
                                    <ReactSpeedometer
                                        minValue={0}
                                        maxValue={120}
                                        ringWidth={10}
                                        forceRender={true}
                                        value={props.tempMedia}
                                        needleColor={
                                            props.tema ? "#fff" : "black"
                                        }
                                        segments={6}
                                        needleTransitionDuration={100}
                                        width={170}
                                        height={120}
                                        textColor={
                                            props.tema ? "#fff" : "black"
                                        }
                                        labelFontSize="10px"
                                        valueTextFontSize="14px"
                                        startColor="transparent"
                                        endColor="red"
                                        currentValueText="${value} °C"
                                    />
                                </EstiloBoxConteudo>
                                <EstiloBoxRodape>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().tMaxTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().tMaxTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Temp. máxima {props.tempMax} °C
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().tMinTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().tMinTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Temp. mínima {props.tempMin} °C
                                        </Tooltip>
                                    </div>
                                </EstiloBoxRodape>
                            </EstiloBox>
                            <EstiloBox tema={props.tema}>
                                <EstiloBoxTitulo>
                                    <i className="fas fa-oil-can"></i> Pressão
                                    do Oleo
                                </EstiloBoxTitulo>
                                <EstiloBoxConteudo>
                                    <ReactSpeedometer
                                        minValue={0}
                                        maxValue={100}
                                        ringWidth={10}
                                        value={props.pressaoMedia}
                                        needleColor={
                                            props.tema ? "#fff" : "black"
                                        }
                                        segments={10}
                                        needleTransitionDuration={100}
                                        width={170}
                                        height={120}
                                        textColor={
                                            props.tema ? "#fff" : "black"
                                        }
                                        labelFontSize="10px"
                                        valueTextFontSize="14px"
                                        startColor="transparent"
                                        endColor="grey"
                                        currentValueText="${value} psi"
                                    />
                                </EstiloBoxConteudo>
                                <EstiloBoxRodape>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().pMaxTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().pMaxTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Pressão máxima{" "}
                                            {props.pressaoMax.toFixed(2)}
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().pMinTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().pMinTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Pressão mínima{" "}
                                            {props.pressaoMin.toFixed(2)}
                                        </Tooltip>
                                    </div>
                                </EstiloBoxRodape>
                            </EstiloBox>
                            <EstiloBox tema={props.tema}>
                                <EstiloBoxTitulo>
                                    <i className="fas fa-tools"></i> Carga do
                                    Motor
                                </EstiloBoxTitulo>
                                <EstiloBoxConteudo>
                                    {props.cargaMedia} %
                                </EstiloBoxConteudo>
                                <EstiloBoxRodape>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().cMaxTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().cMaxTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Carga máxima {props.cargaMax} %
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().cMinTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().cMinTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Carga mínima {props.cargaMin} %
                                        </Tooltip>
                                    </div>
                                </EstiloBoxRodape>
                            </EstiloBox>
                            <EstiloBox tema={props.tema}>
                                <EstiloBoxTitulo>
                                    <i className="fas fa-cogs"></i> Torque do
                                    Motor
                                </EstiloBoxTitulo>
                                <EstiloBoxConteudo>
                                    <Chart
                                        type="radialBar"
                                        series={[props.torqMedia]}
                                        options={{
                                            theme: {
                                                mode: props.tema
                                                    ? "dark"
                                                    : "light",
                                            },
                                            chart: {
                                                offsetY: -20,
                                                sparkline: {
                                                    enabled: true,
                                                },
                                            },
                                            colors: ["#205fad"],
                                            plotOptions: {
                                                radialBar: {
                                                    startAngle: -90,
                                                    endAngle: 90,
                                                    track: {
                                                        background: props.tema
                                                            ? "#606060"
                                                            : "#d6d2d2",
                                                        strokeWidth: "97%",
                                                        margin: 5, // margin is in pixels
                                                    },
                                                    dataLabels: {
                                                        name: {
                                                            show: false,
                                                        },
                                                        value: {
                                                            offsetY: -2,
                                                            fontSize: "22px",
                                                        },
                                                    },
                                                },
                                            },
                                            grid: {
                                                padding: {
                                                    top: -10,
                                                },
                                            },
                                        }}
                                        height={200}
                                    />
                                </EstiloBoxConteudo>
                                <EstiloBoxRodape>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().tqMaxTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().tqMaxTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Torque máxima {props.torqMax} %
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip
                                            title={
                                                <>
                                                    <i
                                                        className="far fa-clock"
                                                        style={{
                                                            marginRight: "10px",
                                                        }}
                                                    ></i>
                                                    {graficoRpmVazao().tqMinTime.toLocaleDateString(
                                                        "pt-BR",
                                                        {
                                                            weekday: "long",
                                                            day: "2-digit",
                                                            month: "long",
                                                            year: "numeric",
                                                            hour12: true,
                                                        }
                                                    )}
                                                    {" - "}
                                                    {graficoRpmVazao().tqMinTime.toLocaleTimeString(
                                                        "pt-BR"
                                                    )}
                                                </>
                                            }
                                            placement="bottom"
                                        >
                                            Torque mínima {props.torqMin} %
                                        </Tooltip>
                                    </div>
                                </EstiloBoxRodape>
                            </EstiloBox>
                        </>
                    )}
                </EstiloBoxGeral>
            </Modal>
        );
    } else {
        return (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spinner view={true}>
                    <i
                        className="fas fa-spinner"
                        style={{ fontSize: "50px", color: "#FFF" }}
                    ></i>
                </Spinner>
            </div>
        );
    }
}

function mapStateToProps(store: IStore) {
    return {
        tiposOp: store.tiposOp,
    };
}

function mapDispatchToProps(dispatch: any) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEquipa);
